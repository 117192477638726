import React, { useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import NavBar from "./Navbar";
import Footer from "./Footer";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const Mfa = () => {
  const [code, setCode] = useState(Array(6).fill(""));
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (value, index) => {
    if (/^[0-9]?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < code.length - 1) {
        document.getElementById(`code-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && !code[index]) {
      document.getElementById(`code-input-${index - 1}`).focus();
    }
  };

  const handleSubmit = async () => {
    const authCode = code.join("");
    const email = localStorage.getItem("email");
  
    if (!authCode || authCode.length !== 6) {
      toast.error("Please enter a valid 6-digit code.");
      return;
    }
  
    try {
      const formDataToSend = {
        email: email,
        verification_code: authCode,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/verify2fa`
        ,
        formDataToSend
      );
  
      const { message } = response.data.message;
      console.log(response, "mfa");
  
      if (response.data.status === 200) {
        const { email } = response.data.result.token;
        const { access_token } = response.data.result.token;
        localStorage.setItem("token", access_token);
        console.log(access_token, "mfa token ");
        localStorage.setItem("email", email);
  
        toast.success(message || "2FA Verified now Logging in");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else if (response.data.status === 400) {
        // Handle 400 error properly
        toast.error(response.data.message || "OTP not Verified");
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      // Handling errors more explicitly
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(
            "OTP code expired. Please regenerate and enter a new OTP code."
          );
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message || "OTP not Verified");
        } else {
          toast.error("An error occurred. Please try again.");
        }
      } else {
        toast.error("Network error. Please check your connection.");
      }
    }
  };
  
  const ResendSubmit = async () => {
    const email = localStorage.getItem("email"); 
    const token = localStorage.getItem("token");  // Retrieve token if necessary for authentication
  
    if (!email) {
      toast.error("No email found. Please log in again.");
      return;
    }
  
    console.log("Email from localStorage:", email);  // Debugging: Check if email is correctly retrieved
  
    try {
      // Prepare the form data
      const formData = new FormData();
      formData.append("email", email);

      // Make the API request
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/resend_mfa_code`
        ,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",  // Ensure the request is sent as form-data
            // Include token if required by the API for authentication
            ...(token && { "Authorization": `Bearer ${token}` }),
          },
        }
      );
  
      console.log("Response data:", response.data);  // Debugging: Check the response data
  
      const { message } = response.data;
  
      // Handle success and failure responses
      if (response.data.status === 200) {
        localStorage.setItem("is_google_user", JSON.stringify(response.data.details.is_google_user));
        toast.success(message || "MFA code resent successfully.");
      } else if (response.data.status === 400) {
        toast.error(response.data.message || "Unable to resend the OTP.");
      } else {
        toast.error(response.data.message || "An error occurred while resending.");
      }
    } catch (error) {
      console.error("Error:", error);  // Debugging: Log the error for more detail
  
      // Error handling
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(
            "Request failed. Please generate and enter a new OTP code."
          );
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message || "OTP could not be resent.");
        } else {
          toast.error("An error occurred. Please try again.");
        }
      } else {
        toast.error("Network error. Please check your connection.");
      }
    }
  };
  
  
  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",
      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    const scriptElements = scripts.map((src) => loadScript(src));

    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  return (
    <div>
      <div id="preloader">
        <div id="loader"></div>
      </div>
      <div className="my-app home-seven">
        <NavBar />
        <section
          className="section cmn-banner"
          data-background="assets/images/banner/banner-bg.png"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="cmn-banner__content wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="300ms"
                >
                  <h3 className="h3">MFA</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        MFA
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="anime">
            <img
              src="assets/images/anime-one.png"
              alt="Image"
              className="one"
            />
            <img
              src="assets/images/anime-two.png"
              alt="Image"
              className="two"
            />
          </div>
        </section>

        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{ style: { marginTop: "50px" } }}
        />

        <div style={styles.container}>
          <h2 style={styles.heading}>Enter Authentication Code</h2>
          <p>
            To proceed, please enter the authentication code sent to your
            registered email or phone number.
          </p>
          <div style={styles.authCodeContainer}>
            {code.map((digit, index) => (
              <input
                key={index}
                type="text"
                id={`code-input-${index}`}
                value={digit}
                maxLength="1"
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                style={styles.authCodeInput}
              />
            ))}
          </div>
          <div className="d-flex gap-2">   <button onClick={handleSubmit} style={styles.submitButton}>
            Submit
          </button>
          <button onClick={ResendSubmit} style={styles.submitButton}>
           Resend
          </button></div>
       
        </div>

        <Footer />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
    padding: "20px",
    backgroundColor: "#f9f9f9",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
  },
  authCodeContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0",
  },
  authCodeInput: {
    width: "50px",
    height: "50px",
    margin: "0 10px",
    fontSize: "24px",
    borderRadius: "8px",
    border: "2px solid #ccc",
    textAlign: "center",
    outline: "none",
    transition: "border-color 0.2s ease-in-out",
  },
  submitButton: {
    marginTop: "20px",
    padding: "10px 20px",
    fontSize: "18px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default Mfa;
