import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import Event from "./Event";
export default function Setting() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMFAEnabled, setIsMFAEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const isGoogleUser = localStorage.getItem("is_google_user") === "true";
  console.log(isGoogleUser , typeof(isGoogleUser))
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const fetch2FAStatus = async () => {
      try {

        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/get2fa_status`
          ,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response, "getenable");
        if (response.status === 200) {
          const { enable_2fa } = response.data.result[0];
          setIsMFAEnabled(enable_2fa);
        } else {
          toast.error("Failed to fetch 2FA status.");
        }
      } catch (error) {
        if (error.response) {
          toast.error(
            error.response.data.message || "Failed to fetch 2FA status."
          );
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      } finally {
        setLoading(false); // Stop loading after fetching status
      }
    };

    fetch2FAStatus();
  }, []);
  const username = localStorage.getItem('username');

  const [formData, setFormData] = useState({
    email: "",
  });
  const [error, setError] = useState("");
  const handleToggleMFA = async () => {
    try {
      const token = localStorage.getItem("token");

      const enableOrDisableMFA = isMFAEnabled ? "disable" : "enable";

      const formDataToSend = new FormData();
      formDataToSend.append("enable_or_disable_2fa", enableOrDisableMFA);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/enable2fa`
        ,
        formDataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        // setIsMFAEnabled(!isMFAEnabled); 
      } else {
        toast.error("Unexpected response status");
      }
    } catch (error) {
      if (error.response) {
        toast.error(
          error.response.data.message || "Failed to update MFA setting."
        );
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  console.log("Google user status:", isGoogleUser, typeof isGoogleUser);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted");
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("email", formData.email);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/change_password_inside_dashboard`
        ,
        formDataToSend
      );

      console.log("Response data:", response.data);

      if (response.status === 200) {
        toast.success(response.data.message, "Sign In Successful!");

        setTimeout(() => {
          // navigate("/chat");
        }, 1000);
      } else {
        toast.error("Unexpected response status");
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.details.message);
        toast.error(error.response.data.details.message);
      } else {
        setError("Something went wrong. Please try again.");
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  const handleSignOut = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("No token found, please login again.");
      navigate("/signin");
      return;
    }

    try {

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/api/sign_out`,

        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "logout");
      if (response.status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("is_google_user");

        toast.success("Logout successfully");
        navigate("/signin");
      } else {
        const errorData = await response.json();
        toast.error(`Sign-out failed: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("An error occurred while signing out. Please try again.");
    }
  };
 
  const containerStyle = {
    backgroundColor: "#eee",
    borderRadius: "10px",
    padding: "20px",
    width: "100%",
    maxWidth: isMediumScreen ? "800px" : "1000px", 
    margin: "0 auto",
    marginTop: "40px",
   
  };
  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />
       <div
        className={`flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 ${
          isSidebarOpen ? "md:ml-[350px] md:w-[calc(100%-350px)] lg:ml-[400px] lg:w-[calc(100%-400px)] xl:ml-[350px] xl:w-[calc(100%-350px)]" : "w-full"
        }`}
      >
      <div
        className=" flex flex-col justify-center items-center mt-2"      >
        <h1
          style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
        >
          Settings
        </h1>

 

        {/* <div */}
        <div   className="mt-10" style={{ ...containerStyle, maxWidth: "750px" }}>
          <Event/>
        </div>
          <div   className="mt-10" style={{ ...containerStyle, maxWidth: "750px" }}>

      
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "left",
            }}
          >
            Reset Password
          </h2>
          <form
            onSubmit={handleSubmit}
            action="#"
            method="post"
            style={{ width: "100%" }} 
          >
            <div className="input-single" style={{ marginBottom: "1rem" }}>
              <p
                style={{
                  marginBottom: "0.5rem",
                  fontSize: "0.9rem",
                  color: "#555",
                  textAlign: "left", 
                }}
              >
                Please enter your email address to reset your password.
              </p>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email address"
                style={{
                  width: "80%", 
                  padding: "0.75rem",
                  borderRadius: "8px", 
                  border: "1px solid #ccc",
                  boxSizing: "border-box",
                  textTransform: "none",
                }}
                required
              />
            </div>
            
            <div className="form-cta">
            <button
  type="submit"
  aria-label="create new event"
  className="btn rounded-lg btn--octonary"
  style={{
    backgroundColor: "#0056b3",
    color: "#fff",
    padding: "12px 22px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s ease, transform 0.2s ease",
  }}
  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
>
Submit{" "}</button>
             
            </div>
          </form>
        </div>

      
        {/* Two-factor authentication */}
        {Boolean (isGoogleUser) === false && (
          <div   className="mt-10" style={{ ...containerStyle, maxWidth: "750px" }}>

          <h2
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Two-factor Authentication
          </h2>
          <p
            style={{
              marginBottom: "20px",
              fontSize: "16px",
              color: "#555",
            }}
          >
            {isMFAEnabled
              ? "Two-factor authentication is enabled. Click below to disable it."
              : "Two-factor authentication is disabled. Click below to enable it."}
          </p>
          <button
            onClick={handleToggleMFA}
            type="submit"
  aria-label="create new event"
  className="btn rounded-lg btn--octonary mt-4"
  style={{
    backgroundColor: isMFAEnabled ? "#dc3545" : "#0056b3",
    color: "#fff",
    padding: "12px 16px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",

  }}
  
>
{loading
              ? "Loading..."
              : isMFAEnabled
              ? "Disable 2FA"
              : "Enable 2FA"}
</button>
         
        </div>
        ) }
        
        {/* Logout */}
        <div   className="mt-10" style={{ ...containerStyle, maxWidth: "750px" }}>

          <h2
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Logout
          </h2>
          <p
            style={{
              marginBottom: "20px",
              fontSize: "16px",
              color: "#555",
            }}
          >
 You are signed in as {username }          </p>
 <div className="form-cta">
            <button
                onClick={handleSignOut}
                type="submit"  aria-label="create new event"
  className="btn rounded-lg btn--octonary"
  style={{
    backgroundColor: "#0056b3",
    color: "#fff",
    padding: "12px 22px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s ease, transform 0.2s ease",
  }}
  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
>
Logout</button>
             
            </div>

         
        </div>
      </div>
      </div>
    </div>
  );
}
