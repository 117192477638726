import React from "react";
import { Skeleton } from "@mui/material";

export default function DropdownSkeleton() {
  return (
    <div className="container mx-auto px-4">
      <div className="container mx-auto" style={{ width: "90%" }}>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6">
      {[1, 2, 3].map((item) => (
            <div key={item} className="bg-white rounded-lg shadow-lg overflow-hidden min-h-[500px]">
              <div className="p-6">
                {/* Doctor Header Skeleton */}
                <div className="flex items-start mb-4 min-h-[120px]">
                  <Skeleton variant="circular" width={80} height={80} />
                  <div className="ml-4 flex-1">
                    <Skeleton variant="text" width="60%" height={32} />
                    <Skeleton variant="text" width="40%" height={24} />
                    <div className="flex items-center mt-2">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Skeleton key={star} variant="rectangular" width={20} height={20} className="mr-1" />
                      ))}
                    </div>
                  </div>
                </div>

                {/* Tabs Skeleton */}
                <div className="flex flex-wrap gap-2 md:gap-4 mb-4 md:mb-6">
                <Skeleton variant="rectangular" width={80} height={36} />
                  <Skeleton variant="rectangular" width={80} height={36} />
                  <Skeleton variant="circular" width={32} height={32} />
                    </div>
               

                {/* Overview Content Skeleton */}
                <div className="space-y-4 mt-2">
                  <div>
                    <Skeleton variant="text" width="30%" height={24} />
                    <Skeleton variant="text" width="80%" height={20} />
                    <Skeleton variant="text" width="70%" height={20} />
                  </div>
                  <div className=" mt-2">
                    <Skeleton variant="text" width="40%" height={24} />
                    <Skeleton variant="text" width="50%" height={20} />
                  </div>
                  <div className=" mt-3">
                    <Skeleton variant="text" width="30%" height={24} />
                  
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}