
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import Skeleton from "@mui/material/Skeleton";

export default function Upgrade() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [planType, setPlanType] = useState("monthly");
  const [pricingData, setPricingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);

  const spinnerContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };

  const spinnerStyles = {
    width: '5rem',
    height: '5rem',
    color: '#005477',
  };

  useEffect(() => {
    const fetchPlans = async () => {
      const formData = new FormData();
      formData.append("plan_name", planType);

      try {
        setLoading(true); 
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client_handler/subscription_plan`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data", 
            },
          }
        );

        if (response.data.status === 200) {
          setPricingData(response.data.result);
        } else {
          toast.error("Failed to load subscription plans.");
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      } finally {
        setLoading(false); 
      }
    };

    fetchPlans();
  }, [planType]);

  const handlePlanChange = (type) => {
    setPlanType(type);
  };

  const getButtonStyle = (type) => ({
    backgroundColor: planType === type ? "#111827" : "#f3f4f6",
    color: planType === type ? "white" : "#111827",
    transform: planType === type ? "scale(1.1)" : "scale(1)",
    transition: "transform 0.3s ease",
    fontWeight: planType === type ? "bold" : "normal",
  });

  const handleSubmit = async (price_id, title) => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client_handler/create_checkout_session`,
          {
            price_id,
            title,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data.status === 200) {
          window.location.href = response.data.url;
        } else {
          toast.error("Cannot proceed with payment");
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    } else {
      window.location.href = "/auth/login";
    }
  };

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />
    
      <div
        className={`flex flex-col mt-2 p-5 justify-center items-center ${
          isSidebarOpen ? "md:ml-[350px] md:w-[calc(100%-350px)]" : "w-full"
          
        }` }
        style={{
          transition: "width 0.3s, margin-left 0.3s",
          backgroundColor: "white",
        }}
      > 
       
          <section
          style={{ backgroundColor: "white", marginTop: "-30px", overflowY: "hidden" }}
        >
          
          <div className="container">
            <div className="row justify-content-center">
              <div>
                <div className="section__header">
                  <h3 style={{ width: "100%", textAlign: "center", marginBottom: "10px" }} className="h3">
                    Pricing Plan
                  </h3>
                  <h6 style={{ width: "100%", textAlign: "center", color: "#6b7280" }} className="h6">
                    Choose a Plan That Works for You!
                  </h6>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col items-center">
            <div className="d-flex flex-wrap justify-content-center mt-4 gap-3">
              <button
                onClick={() => handlePlanChange("monthly")}
                style={getButtonStyle("monthly")}
                className="btn  me-2"
              >
                Monthly Plan
              </button>
              <button
                onClick={() => handlePlanChange("yearly")}
                style={getButtonStyle("yearly")}
                className="btn  me-2"
              >
                Yearly Plan
              </button>
            </div></div>
            {loading ? (
              <div className="w-full flex justify-center">
        <div className="container">
        <div className="row justify-content-center gap-3" style={{ marginTop: "50px" }}>
          {[...Array(2)].map((_, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4">
              <div
                style={{
                  backgroundColor: "white",
                  border: "1px solid #ddd",
                  borderRadius: "15px",
                  padding: "20px",
                  margin: "90px 75px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  minWidth: "380px",
                  maxWidth: "420px",
                  width: "90%",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={40}
                  style={{ marginBottom: "20px" }}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="80%"
                  height={50}
                  style={{ margin: "0 auto 20px" }}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={1}
                  style={{ marginBottom: "20px" }}
                />
                {[...Array(6)].map((_, waveIndex) => (
                  <Skeleton
                    key={waveIndex}
                    variant="text"
                    animation="wave"
                    width="100%"
                    height={20}
                    style={{ marginBottom: "12px" }}
                  />
                ))}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="60%"
                  height={40}
                  style={{ margin: "20px auto 0" }}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="60%"
                  height={40}
                  style={{ margin: "20px auto 0" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div></div>
        ) : (
          <div className="row justify-content-center w-100 items-gap gap-3 " style={{ maxWidth: "1200px" }}>
              {pricingData.map((plan, index) => ( 
                <div key={plan.plan_id} className="col-12 col-md-6 col-xl-4   d-flex justify-content-center" >
                  <div
                    className="pricing-two__single wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="1200ms"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #e0e0e0",
                      borderRadius: "15px",
                      padding: "20px",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      transform: hoveredCard === plan.plan_id ? "translateY(-5px)" : "none",
                      boxShadow: hoveredCard === plan.plan_id ? "0 4px 20px rgba(0, 0, 0, 0.1)" : "0 2px 10px rgba(0, 0, 0, 0.05)",
                      minHeight: "450px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      minWidth: "380px",
                      maxWidth: "420px",
                      width: "90%",
                        margin: "90px 75px",
                        zIndex: hoveredCard === plan.plan_id ? 1 : 0,
  willChange: "transform"
                    }}
                    onMouseEnter={() => setHoveredCard(plan.plan_id)}
                    onMouseLeave={() => setHoveredCard(null)}
                  >
                    <div>
                      <h5 className={`h5 text-center ${index === 0 ? "pri" : "tri"}`}>
                        {plan.plane_name_ui} 
                      </h5>
                      <h2 className="h2 title text-center" style={{ color: "#111827", margin: "15px 0" }}>
                {plan.price}
                {plan.discount && (
                  <span style={{
                    fontSize: "20px",
                    color: "#10b981",
                    marginLeft: "8px",
                    fontWeight: "700",
                      display: "block",

                  }}>
                     {plan.discount}
                  </span>
                )}
                      </h2>
                      <hr style={{ borderColor: "#e0e0e0", margin: "15px 0" }} />
                      <ul style={{ listStyle: "none",
                padding: "0",
                margin: "0",
                display: "flex",
                flexDirection: "column",
                gap: "12px" }}>
                  <li style={{ 
                  display: "flex",
                  alignItems: "flex-start"  
                }}>
                  <i className="fa-solid fa-check" style={{ 
                    color: "#10b981", 
                    marginRight: "12px",
                    minWidth: "20px",  
                    paddingTop: "2px"  
                  }}></i>
                  {plan.health_dashboard}
                </li>
               
                       <li style={{ 
                  display: "flex",
                  alignItems: "flex-start"  
                }}>
                  <i className="fa-solid fa-check" style={{ 
                    color: "#10b981", 
                    marginRight: "12px",
                    minWidth: "20px",  
                    paddingTop: "2px"  
                  }}></i>{plan.notifications_alerts}</li>
                       <li style={{ 
                  display: "flex",
                  alignItems: "flex-start"  
                }}>
                  <i className="fa-solid fa-check" style={{ 
                    color: "#10b981", 
                    marginRight: "12px",
                    minWidth: "20px",  
                    paddingTop: "2px"  
                  }}></i>File Upload : {plan.file_upload}</li>
                     <li style={{ 
                  display: "flex",
                  alignItems: "flex-start"  
                }}>
                  <i className="fa-solid fa-check" style={{ 
                    color: "#10b981", 
                    marginRight: "12px",
                    minWidth: "20px",  
                    paddingTop: "2px"  
                  }}></i>File Upload : {plan.file_size}</li>
                 <li style={{ 
                  display: "flex",
                  alignItems: "flex-start"  
                }}>
                  <i className="fa-solid fa-check" style={{ 
                    color: "#10b981", 
                    marginRight: "12px",
                    minWidth: "20px",  
                    paddingTop: "2px"  
                  }}></i> {plan.report_generation}</li>
                     <li style={{ 
                  display: "flex",
                  alignItems: "flex-start"  
                }}>
                  <i className="fa-solid fa-check" style={{ 
                    color: "#10b981", 
                    marginRight: "12px",
                    minWidth: "20px",  
                    paddingTop: "2px"  
                  }}></i> {plan.medical_chatbot}</li>
                       
                      </ul>
                      <hr style={{ borderColor: "#e0e0e0", margin: "15px 0" }} />
                      <p style={{ color: "#6b7280", textAlign: "center", margin: "0" }}>Expire Date : {plan.expiry_date}</p>
                    </div>
                    <button
                      onClick={() => handleSubmit(plan.price_id, plan.plane_name_ui)}
                      className="btn mt-2"
                      style={{
                        backgroundColor: "#111827",
                        color: "white",
                        width: "60%", 
                        padding: "12px 20px", 
                        borderRadius: "8px",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "15px auto 0", 
                        display: "block"
                      }}
                    >
                      Buy now
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
       
        </section>
       
      </div>
    </div>
  );
}