




// Dashboard layout



import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Chatbot from "./Chatbot";
import Upgrade from "./Upgrade";
import Plandetails from "./Plandetails";
import { Modal, Box, Button } from "@mui/material";
import Setting from "./Setting";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { BiDiamond, BiCube } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function Dashboardlayout() {
  const [activeComponent, setActiveComponent] = useState("chatbot");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/check-token/`,
          { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );
        

        console.log(response.data ,"token"); 

        if (response.status === 200) {
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data?.messages?.[0]?.message ===
            "Token is invalid or expired"
        ) {
          navigate("/"); // Redirect to /signin
        }
      }
    };

    checkToken();

    // Open modal if popup setting is set in local storage
    const displayPopup = window.localStorage.getItem("display_popup");
    if (displayPopup === "true") {
      setOpenModal(true);
    }
  }, [navigate]);


  const handleUpgradeClick = () => {
    setActiveComponent("upgrade");
  };
  const handleHistoryClick = () => {
    setActiveComponent("history");
  };
  const handlePlanClick = () => {
    setActiveComponent("plan");
  };
  const handleSettingClick = () => {
    setActiveComponent("setting");
  };
  const handleCloseModal = (openUpgrade = false) => {
    setOpenModal(false);
    window.localStorage.removeItem("display_popup");
    if (openUpgrade) {
      setActiveComponent("upgrade");
    }
  };
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Sidebar
        onUpgradeClick={handleUpgradeClick}
        onHistoryClick={handleHistoryClick}

        onPlanClick={handlePlanClick}
        onSettingClick={handleSettingClick}
      />
      <div
        style={{
          flexGrow: 1,
       
          paddingLeft: "30px", 
        }}
      >
        <Outlet />
      </div>

      <Modal
  open={openModal}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      position: "absolute",
      top: "10%",
      left: "50%",
      transform: "translate(-50%, 0)",
      width: { xs: "90%", sm: "80%", md: "60%" }, 
      overflowY: "auto",
      bgcolor: "background.paper",
      border: "none", 
      borderRadius: "16px",
      boxShadow: 24,
      p: 4,
    }}
  >
    <div className="mb-4" style={{ textAlign: "center", marginBottom: "20px" }}>
      <h2 style={{ fontSize: "2rem", fontWeight: "bold", color: "black" }}>
      Get More from MedicalGPT!
      </h2>
      <p>You're currently on the <span style={{ fontWeight: "bold", color: "black" }}>Free Plan</span> – upgrade to <span style={{ fontWeight: "bold", color: "black" }}>Pro</span> or <span style={{ fontWeight: "bold", color: "black" }}>Premium</span> for extra features and deeper insights!</p>
    </div>
    <h5 style={{ fontWeight: "bold", marginTop: "10px" }}>Premium Features Include:</h5>
    <ul style={{ marginTop: "20px", marginBottom: "20px", listStyleType: "none", padding: 0 }}>
      {[
        { text: "Unlimited Medical Queries – No daily limits!", icon: "bi bi-heart" },
        { text: "Expanded File Uploads – Analyze more medical documents", icon: "bi bi-credit-card" },
        { text: " Advanced Report Generation – Get detailed insights and downloadable reports", icon: "bi bi-file-earmark-pdf" },
        { text: "Full Health Dashboard Access – Track your health data in one place", icon: "bi bi-graph-up" },
        { text: "Personalized Notifications & Alerts – Stay informed with health reminders", icon: "bi bi-people" },
      ].map((feature, index) => (
        <li
          key={index}
          style={{ fontSize: "17px", display: "flex", alignItems: "center", marginBottom: "10px" }}
        >
          <span style={{ color: "green", fontWeight: "bold", marginRight: "10px" }}>✔</span>
          <i className={`${feature.icon}`} style={{ marginRight: "10px", color: "#444" }}></i>
          {feature.text}
        </li>
      ))}
    </ul>
    {/* <h5 style={{ fontWeight: "bold", marginTop: "10px" }}>Plan Options:</h5>
    <ul style={{ marginTop: "20px", listStyleType: "none", padding: 0 }}>
    <li style={{ fontSize: "17px", display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <span style={{ marginRight: "10px", display: "flex", alignItems: "center", color: "#3498db" }}>
          <BiDiamond size={20} />
        </span>
        <strong>Monthly Premium:</strong> $50/month
      </li>
      <li style={{ fontSize: "17px", display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "10px", display: "flex", alignItems: "center", color: "#e74c3c" }}>
          <BiCube  size={20} />
        </span>
        <strong>Yearly Premium:</strong> $540/year (Save 10%)
      </li>
    </ul> */}
    <div style={{ textAlign: "center", marginTop: "30px" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleCloseModal(false)}
        sx={{ marginRight: "10px" }}
        style={{borderRadius:"10px", backgroundColor:"#756cf5" , color:"white"}}
      >
        Free Trial
      </Button>
      <Link to="/upgrade">
      <Button variant="contained" color="success"         style={{borderRadius:"10px",color:"white"}}
 onClick={() => handleCloseModal(true)}>
        Upgrade
      </Button>
      </Link>
    </div>
  </Box>
</Modal>


    </div>
  );
}
