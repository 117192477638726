import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import {
  FaHeart,
  FaTint,
  FaRunning,
  FaBed,
  FaAppleAlt,
  FaQuoteLeft,
  FaUser,
  FaBrain,
  FaShieldVirus,
  FaSmile,
  FaEdit,
  FaTrash,
  FaPlus,
} from "react-icons/fa";
import CreateProfile from "./Createprofile";
import SkeletonDashboard from "./SkeletonDashboard";

export default function Dashboard() {
  const [dailyAlert, setDailyAlert] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [profileExists, setProfileExists] = useState(null); 
  const [healthDetails, setHealthDetails] = useState(null); 
  const [personalInfo, setPersonalInfo] = useState(null); 
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const [editId, setEditId] = useState(null);
const [formData, setFormData] = useState({
  age: "",
  weight: "",
  height: "",
  medication: "",
  diseases: "",
  heart_rate: "",
  blood_pressure: "",
  glucose_level: "",
});
const [isCreating, setIsCreating] = useState(false);
const [isFormOpen, setIsFormOpen] = useState(false);
const [clickedIcon, setClickedIcon] = useState(null);
const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

useEffect(() => {
  const storedUsername = localStorage.getItem("username");
  if (storedUsername) {
    setUsername(storedUsername);
  }
}, []);

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/user_health_dashboard`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(response, "API Response");

        if (response.data.profileExists === false) {
          setProfileExists(false);
        } else {
          setProfileExists(true);
          setHealthDetails(response.data.healthDetails);
          setEditId(response.data.personal_info.id);
          setPersonalInfo(response.data.personal_info);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setProfileExists(false);
      }
      finally {
        setLoading(false);
      }
    };
    useEffect(() => {
    fetchData();
  }, [profileExists]);
  
  const handleEditClick = (id) => {
    setEditId(id);
    setIsCreating(false);
    setIsFormOpen(true);
    if (personalInfo) {
      setFormData({
        age: personalInfo.age,
        weight: personalInfo.weight,
        height: personalInfo.height,
        medication: personalInfo.medication || "",
        diseases: personalInfo.diseases || "",
        heart_rate: personalInfo.heart_rate || "",
        blood_pressure: personalInfo.blood_pressure || "",
        glucose_level: personalInfo.glucose_level || "",
      });
    }
  };
  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      blood_pressure: `${name === "systolic" ? value : prev.systolic || ""}/${name === "diastolic" ? value : prev.diastolic || ""}`,
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      
      const url = `${process.env.REACT_APP_API_URL}/user/user-personal-info/${editId}/update/`;
  
      // Convert empty values to null and handle numbers
      const sanitizedData = {
        age: formData.age ? Number(formData.age) : null,
        weight: formData.weight ? Number(formData.weight) : null,
        height: formData.height || null,
        medication: formData.medication || null,
        diseases: formData.diseases || null,
        heart_rate: formData.heart_rate ? Number(formData.heart_rate) : null,
        blood_pressure: formData.blood_pressure || null,
        glucose_level: formData.glucose_level || null
      };
  
      // Always use PUT for both operations
      const response = await axios.put(url, sanitizedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      console.log("Submitting:", sanitizedData);
  
      if (response.status === 200) {
        toast.success(`Profile ${isCreating ? 'created' : 'updated'} successfully!`, {
          position: "top-right",
          duration: 3000,
        });
        setIsFormOpen(false);
        fetchData();
      }
    } catch (error) {
      console.error("API Error:", error.response?.data);
      toast.error(`Operation failed: ${error.response?.data?.detail || "Check console"}`, {
        position: "top-right",
        duration: 5000,
      });
    }
  };
  
 // Handle delete
 const handleDelete = async () => {
  if (!personalInfo?.id) return; // Ensure personalInfo and id exist

  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/user/user-personal-info/${personalInfo.id}/delete/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      // Refetch data after successful deletion
      await fetchData();
      toast.success("Personal information deleted successfully!", {
        position: "top-right",
        duration: 3000,
      });
    }
  } catch (error) {
    console.error("Error deleting personal info:", error);
    toast.error("Failed to delete personal information.", {
      position: "top-right",
      duration: 3000,
    });
  } finally {
    setIsDeleteModalOpen(false); // Close modal after deletion
  }
};

  // Set daily alert message
  useEffect(() => {
    if (healthDetails?.alertMessages) {
      const todayAlert =
        healthDetails.alertMessages[
          new Date().getDate() % healthDetails.alertMessages.length
        ];
      setDailyAlert(todayAlert);
    }
  }, [healthDetails]);
  const isPersonalInfoEmpty = (personalInfo) => {
    return (
      personalInfo.age === null &&
      personalInfo.weight === null &&
      personalInfo.height === null &&
      personalInfo.heart_rate === null &&
      personalInfo.blood_pressure === null &&
      personalInfo.glucose_level === null &&
      personalInfo.medication === null &&
      personalInfo.diseases === null
    );
  };
  // If profile does not exist, show the CreateProfile component
  if (profileExists === false) {
    return (
      <div
        className={`flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 ${
          isSidebarOpen ? "md:ml-[450px] md:w-[calc(100%-450px)]" : "w-full"
        }`}
      >
        <div className="max-w-7xl w-full px-5 py-10">
          <CreateProfile onProfileCreated={() => setProfileExists(true)} />
        </div>
      </div>
    );
  }

  if (!healthDetails || !personalInfo) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-gray-600">Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />
      
      {/* Edit Modal */}
      {isFormOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
      <h2 className="text-2xl font-bold mb-6 text-center">
        {isCreating ? "Update Profile" : "Edit Your Profile"}
      </h2>
      <form onSubmit={handleSubmit}>
        {/* Keep the existing form fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Age */}
          <div>
            <label className="block text-gray-700">Age</label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your age"
            />
          </div>

          {/* Weight */}
          <div>
            <label className="block text-gray-700">Weight (kg)</label>
            <input
              type="number"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your weight"
            />
          </div>

          {/* Height */}
          <div>
            <label className="block text-gray-700">Height (ft)</label>
            <input
              type="text"
              name="height"
              value={formData.height}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your height"
            />
          </div>

          {/* Medication */}
          <div>
            <label className="block text-gray-700">Medication</label>
            <input
              type="text"
              name="medication"
              value={formData.medication}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your medications"
            />
          </div>

          {/* Diseases */}
          <div>
            <label className="block text-gray-700">Diseases</label>
            <input
              type="text"
              name="diseases"
              value={formData.diseases}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter any diseases"
            />
          </div>

          {/* Heart Rate */}
          <div>
            <label className="block text-gray-700">Heart Rate (BPM)</label>
            
            <input
              type="number"
              name="heart_rate"
              value={formData.heart_rate}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your heart rate"
            />
          </div>

          {/* Blood Pressure */}
          <div>
  <label className="block text-gray-700">Blood Pressure</label>
  <div className="flex space-x-2">
  {/* Systolic Dropdown */}
  <select
    name="systolic"
    value={formData.systolic}
    onChange={handleChange}
    className="w-1/2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
  >
    <option value="">Systolic</option>
    {Array.from({ length: 201 }, (_, i) => 50 + i).map((num) => (
      <option key={num} value={num}>
        {num}
      </option>
    ))}
  </select>

  <span className="text-xl font-semibold">/</span>

  {/* Diastolic Dropdown */}
  <select
    name="diastolic"
    value={formData.diastolic}
    onChange={handleChange}
    className="w-1/2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
  >
    <option value="">Diastolic</option>
    {Array.from({ length: 121 }, (_, i) => 30 + i).map((num) => (
      <option key={num} value={num}>
        {num}
      </option>
    ))}
  </select>
</div>
</div>


          {/* Glucose Level */}
          <div>
            <label className="block text-gray-700">Glucose Level (mg/dl)</label>
            <input
              type="text"
              name="glucose_level"
              value={formData.glucose_level}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your glucose level"
            />
          </div>
        </div>
       
        {/* Update buttons */}
        <div className="flex justify-between mt-6">
          <button
            type="button"
            onClick={() => setIsFormOpen(false)}
            className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 transition mr-4"
            style={{  borderRadius: "20px",}}

          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transitio"
            style={{backgroundColor:"#0056b3" ,  borderRadius: "20px",}}

          >
            {isCreating ? "Update Changings" : "Save Changes"}
          </button>
        </div>
      </form>
    </div>
  </div>
)}
      {/* {isEditModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
      <h2 className="text-2xl font-bold mb-6 text-center">Edit Your Profile</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      
          <div>
            <label className="block text-gray-700">Age</label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your age"
            />
          </div>

        
          <div>
            <label className="block text-gray-700">Weight (kg)</label>
            <input
              type="number"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your weight"
            />
          </div>

        
          <div>
            <label className="block text-gray-700">Height (ft)</label>
            <input
              type="text"
              name="height"
              value={formData.height}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your height"
            />
          </div>

         
          <div>
            <label className="block text-gray-700">Medication</label>
            <input
              type="text"
              name="medication"
              value={formData.medication}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your medications"
            />
          </div>

       
          <div>
            <label className="block text-gray-700">Diseases</label>
            <input
              type="text"
              name="diseases"
              value={formData.diseases}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter any diseases"
            />
          </div>

       
          <div>
            <label className="block text-gray-700">Heart Rate (BPM)</label>
            
            <input
              type="number"
              name="heart_rate"
              value={formData.heart_rate}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your heart rate"
            />
          </div>

         
          <div>
            <label className="block text-gray-700">Blood Pressure</label>
            <input
              type="text"
              name="blood_pressure"
              value={formData.blood_pressure}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your blood pressure"
            />
          </div>

          
          <div>
            <label className="block text-gray-700">Glucose Level (mg/dl)</label>
            <input
              type="text"
              name="glucose_level"
              value={formData.glucose_level}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your glucose level"
            />
          </div>
        </div>

       
        <div className="flex justify-end mt-6">
          <button
            type="button"
            onClick={() => setIsEditModalOpen(false)}
            className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 transition mr-4"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
)} */}
      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete your personal information? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
                onClick={() => setIsDeleteModalOpen(false)} 
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={handleDelete} 
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

{loading ? <SkeletonDashboard/> : 
      <div
        className={`flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 ${
          isSidebarOpen ? "md:ml-[250px] md:w-[calc(100%-250px)] lg:ml-[250px] lg:w-[calc(100%-250px)] xl:ml-[350px] xl:w-[calc(100%-350px)]" : "w-full"
        }`}
      >
        <div className="max-w-7xl w-full px-5 py-10">
          {/* Header Section */}
          <div className="flex justify-center items-center bg-white p-6 rounded-lg shadow-md mb-10">
  <div>
    <h1 className="text-3xl  text-center font-bold mb-2"  style={{color:"#005477"}}>
      Welcome{username && `, ${username}!`}
    </h1>
    <p className=" text-center text-3xl font-medium" style={{color:"#005477"}}>
      Your daily guide to better health
    </p>
  </div>
 
</div>
         
          <div className="columns-1 md:columns-2 lg:columns-3 gap-6 space-y-6">
                        {/* Health Stats */}
            
            {healthDetails.heart_rate && healthDetails.blood_pressure && healthDetails.glucose_level && (
                   <div className="break-inside-avoid">

         <div className="bg-[#FFEBEB] p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer">
                <div className="flex items-center mb-4">
                  <FaHeart className="text-red-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Health Stats</h2>
                </div>
                <p className="text-gray-600">Heart Rate: {healthDetails.heart_rate} BPM</p>
                <p className="text-gray-600">Blood Pressure: {healthDetails.blood_pressure}</p>
                <p className="text-gray-600">Glucose Level: {healthDetails.glucose_level}</p>
              </div> </div>
            )}

           
   {/* User Personal Info */}
   {isPersonalInfoEmpty(personalInfo) ? (
                       <div className="break-inside-avoid">

  <div className="bg-blue-100-50 p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer">
    <div className="flex items-center mb-4">
      <FaUser className="text-blue-500 text-3xl mr-3" />
      <h2 className="text-2xl font-bold">User Personal Info</h2>
    </div>
    <p className="text-gray-600">No profile information available</p>
    <button
      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition flex items-center"
      onClick={() => {
        setIsCreating(true);
        setIsFormOpen(true);
        setFormData({
          age: "",
          weight: "",
          height: "",
          medication: "",
          diseases: "",
          heart_rate: "",
          blood_pressure: "",
          glucose_level: "",
        });
      }}
      style={{   borderRadius: "20px",
        backgroundColor: "#0056b3",}}
    >
      <FaPlus className="mr-2" />
      Add Profile
    </button>
  </div></div>
) : (
  <div className="break-inside-avoid">
 <div className="bg-blue-100 p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer">
                <div className="flex items-center mb-4">
                  <FaUser className="text-blue-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">User Personal Info</h2>
                </div>
                <p className="text-gray-600">Age: {personalInfo.age|| "None"}</p>
                <p className="text-gray-600">Weight: {personalInfo.weight|| "None"} kg</p>
                <p className="text-gray-600">Height: {personalInfo.height|| "None"}</p>
                <p className="text-gray-600">Heart Rate: {personalInfo.heart_rate|| "None"}</p>
                <p className="text-gray-600">Blood Pressure: {personalInfo.blood_pressure|| "None"}</p>
                <p className="text-gray-600">Glucose Level: {personalInfo.glucose_level|| "None"}</p>
                <p className="text-gray-600">Medication: {personalInfo.medication || "None"}</p>
                <p className="text-gray-600">Diseases: {personalInfo.diseases || "None"}</p>
                <div className="flex justify-end space-x-4 mt-4">
                  <FaEdit
                    className="text-gray-400 text-2xl cursor-pointer hover:text-blue-500"
                    onClick={() => handleEditClick(personalInfo.id)}
                  />
                  <FaTrash
                    className="text-gray-400 text-2xl cursor-pointer hover:text-blue-500"
                    onClick={() => setIsDeleteModalOpen(true)}
                  />
                </div>
              </div> </div>
            )}

            {/* Meditation & Mindfulness */}
            {healthDetails.meditation && (
            <div className="break-inside-avoid">
    <div className="bg-[#FFEBEB] p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group lg:row-span-2 cursor-pointer">
                <h2 className="text-2xl font-bold mb-4">Meditation & Mindfulness</h2>
                <p className="text-gray-700">{healthDetails.meditation}</p>
                <div className="mt-6 bg-white  p-4 rounded-lg border-l-4 border-red-500">
                  <p className="text-lg font-semibold text-red-700">Quote of the Day</p>
                  <p className="italic text-gray-700">
                    <FaQuoteLeft className="inline-block mr-2" />
                    {healthDetails.quote}
                  </p>
                </div>
              </div></div>
            )}

            {/* Exercise Routine */}
            {healthDetails.exerciseRoutine && (
                   <div className="break-inside-avoid">
       <div className="bg-blue-100 p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer">
                <div className="flex items-center mb-4">
                  <FaRunning className="text-blue-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">{healthDetails.exerciseRoutine.title}</h2>
                </div>
                {/* <p className="text-gray-600">{healthDetails.exerciseRoutine.description}</p> */}
                <ul className="mt-4  pl-5 space-y-2 text-gray-700">
                  {healthDetails.exerciseRoutine.suggestions.map((tip, index) => (
                    <li key={index}>{tip}</li>
                  ))}
                </ul>
              </div></div>
            )}

            {/* Sleep Management */}
            {healthDetails.sleepManagement && (
                          <div className="break-inside-avoid">
       <div className="bg-orange-100 p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer">
                <div className="flex items-center mb-4">
                  <FaBed className="text-orange-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">{healthDetails.sleepManagement.title}</h2>
                </div>
                {/* <p className="text-gray-600">{healthDetails.sleepManagement.description}</p> */}
                <ul className="mt-4  pl-5 space-y-2 text-gray-700">
                  {healthDetails.sleepManagement.suggestions.map((tip, index) => (
                    <li key={index}>{tip}</li>
                  ))}
                </ul>
              </div></div>
            )}
{/* Diet Chart */}


            {/* Water Intake */}
            {healthDetails.waterIntake && (
                                  <div className="break-inside-avoid">
       <div className="bg-blue-100 p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer">
                <div className="flex items-center mb-4">
                  <FaTint className="text-blue-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Water Intake</h2>
                </div>
                <p className="text-lg text-gray-700">{healthDetails.waterIntake}</p>
                <div className="mt-4 bg-white p-4 rounded-lg border-l-4 border-blue-500">
                  <p className="font-semibold text-blue-700">Daily Alert</p>
                  <p className="text-gray-600">{dailyAlert}</p>
                </div>
              </div>  </div>
            )}

            {/* Stress Management */}
            {healthDetails.stressManagement && (
                                           <div className="break-inside-avoid">
     <div className="bg-[#FFEBEB] p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer">
                <div className="flex items-center mb-4">
                  <FaBrain className="text-red-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Stress Management</h2>
                </div>
                <p className="text-gray-600">{healthDetails.stressManagement}</p>
              </div>              </div>

            )}

            {/* Disease Management */}
            {healthDetails.diseaseManagement && (
                                                 <div className="break-inside-avoid">
        <div className="bg-indigo-100 p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer">
                <div className="flex items-center mb-4">
                  <FaShieldVirus className="text-indigo-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Disease Management</h2>
                </div>
                <p className="text-gray-600">{healthDetails.diseaseManagement}</p>
              </div> </div>
            )}

            {/* Feel Better Tips */}
            {healthDetails.feelFreeTips && (
                                                        <div className="break-inside-avoid">
       <div className="bg-[#FFEBEB] p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer">
                <div className="flex items-center mb-4">
                  <FaSmile className="text-red-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Feel Better Tips</h2>
                </div>
                <p className="text-gray-600">{healthDetails.feelFreeTips}</p>
              </div> </div>
            )}{healthDetails.dietChart && (
              <div className="break-inside-avoid">
<div className="bg-orange-100 p-6 rounded-lg shadow-lg  hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 hover:scale-[1.02] group cursor-pointer lg:col-span-2">
<div className="flex items-center mb-4">
<FaAppleAlt className="text-orange-500 text-3xl mr-3" />
<h2 className="text-2xl font-bold">{healthDetails.dietChart.title}</h2>
</div>
<p className="text-gray-600 mb-2">{healthDetails.dietChart.description}</p>

<div className="mt-2 text-gray-700 space-y-2">
{Object.entries(healthDetails.dietChart).map(([key, meal]) => {
if (key === 'title' || key === 'description') return null;

return (
<div key={key} className="">
<div className="flex items-center ">
 <span className="font-semibold text-lg text-orange-600">
   {meal.title}
 </span>
</div>
<p className="text-gray-700 pl-4">{meal.description}</p>
</div>
);
})}
</div>
</div> </div>
)}
          </div>
        </div>
      </div>
}
    </div>
  );
}