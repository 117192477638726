import React from "react";
import { Skeleton } from "@mui/material";

const SkeletonDashboard = () => {
  return (
    <div className="flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 md:ml-[250px] md:w-[calc(100%-250px)] lg:ml-[250px] lg:w-[calc(100%-250px)] xl:ml-[350px] xl:w-[calc(100%-350px)]">
      <div className="max-w-7xl w-full px-5 py-10">
        {/* Header Skeleton */}
        <div className="flex justify-center items-center bg-white p-6 rounded-lg shadow-md mb-10">
          <Skeleton variant="text" width={300} height={40} />
        </div>

        <div className="columns-1 md:columns-2 lg:columns-3 gap-6 space-y-6">
          {/* Health Stats Skeleton */}
          <div className="break-inside-avoid">
            <div className="bg-[#FFEBEB] p-6 rounded-lg shadow-lg mb-6">
              <div className="flex items-center mb-4">
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="text" width={150} height={30} className="ml-3" />
              </div>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={200} />
            </div>
          </div>

          {/* User Personal Info Skeleton */}
          <div className="break-inside-avoid">
            <div className="bg-blue-100 p-6 rounded-lg shadow-lg mb-6">
              <div className="flex items-center mb-4">
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="text" width={150} height={30} className="ml-3" />
              </div>
              {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                <Skeleton key={i} variant="text" width="80%" />
              ))}
              <div className="flex justify-end space-x-4 mt-4">
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="circular" width={32} height={32} />
              </div>
            </div>
          </div>

          {/* Meditation & Mindfulness Skeleton */}
          <div className="break-inside-avoid">
            <div className="bg-[#FFEBEB] p-6 rounded-lg shadow-lg mb-6">
              <Skeleton variant="text" width={250} height={30} />
              <Skeleton variant="text" height={60} className="mt-4" />
              <div className="mt-6 bg-white p-4 rounded-lg">
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" height={50} />
              </div>
            </div>
          </div>

          {/* Exercise Routine Skeleton */}
          <div className="break-inside-avoid">
            <div className="bg-blue-100 p-6 rounded-lg shadow-lg mb-6">
              <div className="flex items-center mb-4">
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="text" width={200} height={30} className="ml-3" />
              </div>
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} variant="text" width="90%" />
              ))}
            </div>
          </div>

          {/* Sleep Management Skeleton */}
          <div className="break-inside-avoid">
            <div className="bg-orange-100 p-6 rounded-lg shadow-lg mb-6">
              <div className="flex items-center mb-4">
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="text" width={200} height={30} className="ml-3" />
              </div>
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} variant="text" width="90%" />
              ))}
            </div>
          </div>

          {/* Water Intake Skeleton */}
          <div className="break-inside-avoid">
            <div className="bg-blue-100 p-6 rounded-lg shadow-lg mb-6">
              <div className="flex items-center mb-4">
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="text" width={150} height={30} className="ml-3" />
              </div>
              <Skeleton variant="text" width={200} />
              <div className="mt-4 bg-white p-4 rounded-lg">
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" />
              </div>
            </div>
          </div>

          {/* Diet Chart Skeleton */}
          <div className="break-inside-avoid">
            <div className="bg-orange-100 p-6 rounded-lg shadow-lg mb-6">
              <div className="flex items-center mb-4">
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="text" width={200} height={30} className="ml-3" />
              </div>
              {[1, 2, 3, 4].map((i) => (
                <div key={i} className="mb-4">
                  <Skeleton variant="text" width={120} />
                  <Skeleton variant="text" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonDashboard;