import React from "react";

export default function Howitwork() {
  return (
    <div>
      {" "}
      <div class="my-app home-two-light">
        <section class="section publish">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-xl-10">
                <div class="publish__inner">
                  <div class="row justify-content-center">
                    <div class="col-12">
                      <div
                        class="section__header wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="300ms"
                      >
                        <h2 class="h2">How It Works</h2>
                      </div>
                    </div>
                  </div>
                  <div class="row items-gap">
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content">
                          <h5 class="h5" style={{ color: "black" }}>
                            Medical Chatbot
                          </h5>

                          <p className="p" style={{ color: "black" }}>
                            Get quick and reliable answers to your medical questions. The AI chatbot provides insights on symptoms, treatments, medications, and more.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="300ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content">
                          <h5 class="h5" style={{ color: "black" }}>
                            File Analysis
                          </h5>
                          <p className="p" style={{ color: "black" }}>
                           Upload medical reports, PDFs, Word documents, or images for  analysis. Receive detailed insights and recommendations based on the content.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content">
                          <h5 class="h5" style={{ color: "black" }}>
                            Notifications and Alerts
                          </h5>
                          <p className="p" style={{ color: "black" }}>
                            Stay updated with notifications and alerts for health insight, and prescription reminders.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content" style={{ color: "black" }}>
                          <h5 class="h5" style={{ color: "black" }}>
                            Chat Report Download
                          </h5>
                          <p className="p" style={{ color: "black" }}>
                            Easily download chat history and medical responses for future reference and consultancy.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content" style={{ color: "black" }}>
                          <h5 class="h5" style={{ color: "black" }}>
Doctor, Pharmacy, and Lab Recommendation                          </h5>
                          <p className="p" style={{ color: "black" }}>
Receive recommendations for doctors, pharmacies, and laboratories near you.                           </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content" style={{ color: "black" }}>
                          <h5 class="h5" style={{ color: "black" }}>
                            Health Dashboard
                          </h5>
                          <p className="p" style={{ color: "black" }}>
Access a personalized dashboard displaying your medical insights, history, and health recommendations for healthy life.                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    class="section__content-cta text-center wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <a href="/contactus" class="btn btn--senary">
                      try a free demo
                    </a>

                    <img src="assets/images/frame-three.png" alt="Image" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
