import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { IoMdEye } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa";
import { Toaster, toast } from "react-hot-toast";
import Footer from "./Footer";
import GoogleAuth from "./Googleauth";

export default function Signup() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setconfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");

  const toggleconfirmPasswordVisibility = () => {
    setconfirmPasswordVisible(!confirmpasswordVisible);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailPattern.test(formData.username)) {
    toast.error("Username cannot be an email address.");
    return;
  }
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setLoading(true); 
    try {
      console.log("Sending request to backend with data:", {
        username: formData.username,
        email: formData.email,
        password: formData.password,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/create_user_v1`
        ,
        {
          username: formData.username,
          email: formData.email,
          password: formData.password,
        }
      );
      console.log("signup:", response);
      const { message, result } = response.data;

      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("username", JSON.stringify(result.username));

        toast.success(message);

        setTimeout(() => {
          // navigate("/dashboard/chat");
        }, 1000);
      }
       else if (response.status === 400)
      {
        toast.error(message)
      }
       else {
        toast.error("Unexpected response status");
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
        toast.error(error.response.data.message, {
          duration: 4000,
          style: {
            whiteSpace: "pre-wrap",
          },
        });
      } else {
        setError("Something went wrong. Please try again.");
        toast.error("Something went wrong. Please try again.");
      }
    }
    finally {
      setLoading(false); // Set loading to false when request completes
    }
  };

  
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",
      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <div>
      <div id="preloader">
        <div id="loader"></div>
      </div>
      <div class="my-app home-seven">
        <NavBar />
        <section
            class="section cmn-banner"
            data-background="https://autocontent-backend.s3.amazonaws.com/banner-bg.png"
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div
                    class="cmn-banner__content wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <h3 class="h3">Create Account </h3>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb justify-content-center">
                        <li class="breadcrumb-item">
                          <a href="index.html">Home</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                        Sign Up
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="anime">
              <img src="/assets/images/anime-one.png" alt="Image" class="one" />
              <img src="/assets/images/anime-two.png" alt="Image" class="two" />
            </div>
          </section>
       
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            style: {
              marginTop: "50px",
            },
          }}
        />

        <section class="section authentication pb-0">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10 col-xl-6">
                <div
                  class="authentication__inner wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="300ms"
                >
                  <div class="section__header text-start">
                    <h2 class="h3">Create Account</h2>
                  </div>
                  <form onSubmit={handleSubmit} action="#" method="post">
                    <div class="input-single">
                      <input
                        type="username"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        style={{ textTransform: "none" }}
                        placeholder="User Name"
                        required
                      />
                    </div>
                    <div class="input-single">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        style={{ textTransform: "none" }}
                        placeholder="Email address"
                        required
                      />
                    </div>
                    <div
                      className="input-single flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={passwordVisible ? "text" : "password"}
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Enter password"
                        required
                        style={{
                          width: "100%",
                          paddingRight: "40px",
                          textTransform: "none",
                        }}
                      />
                      <button
                        type="button"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          padding: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="password-toggle-button"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? <FaEyeSlash /> : <IoMdEye />}
                      </button>
                    </div>

                  
                    <div
                      className="input-single flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={confirmpasswordVisible ? "text" : "password"}
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        placeholder="Confirm password"
                        style={{
                          width: "100%",
                          paddingRight: "40px",
                          textTransform: "none",
                        }}
                        required
                      />
                      <button
                        type="button"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          padding: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="password-toggle-button"
                        onClick={toggleconfirmPasswordVisibility}
                      >
                        {confirmpasswordVisible ? <FaEyeSlash /> : <IoMdEye />}
                      </button>
                    </div>
                    <div class="form-cta">
                      <button
                        href="/signin"
                        type="submit"
                        aria-label="post comment"
                        class="btn btn--ocotonary"
                      >
                         {loading ? (
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ color: "#ffffff" }}></span>
                        ) : (
                          "Sign up"
                        )}
                      </button>
                      <div className="mt-4"> <GoogleAuth /></div>
                      <p>
                        Already have an account?
                        <a style={{ marginLeft: "5px" }} href="/signin">
                          Sign in
                        </a>
                      </p>
                    </div>
                  </form>
                  {/* {error && <p class="error">{error}</p>} */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
 
      </div>
      <div class="progress-wrap">
        <svg
          class="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      <script src="assets/js/jquery-3.7.0.min.js"></script>
      <script src="assets/js/bootstrap.bundle.min.js"></script>
      <script src="assets/js/jquery.nice-select.min.js"></script>
      <script src="assets/js/jquery.magnific-popup.min.js"></script>
      <script src="assets/js/slick.min.js"></script>
      <script src="assets/js/typed.umd.js"></script>
      <script src="assets/js/wow.min.js"></script>
      {/* <!-- ==== / js dependencies end ==== --> */}
      {/* <!-- plugins js --> */}
      <script src="assets/js/plugins.js"></script>
      {/* <!-- main js --> */}
      <script src="assets/js/main.js"></script>
    </div>
  );
}
