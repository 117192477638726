import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { CiClock2 } from "react-icons/ci";
import {
  MdWorkspacesOutline,
  MdOutlineFormatIndentIncrease,
} from "react-icons/md";
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiOutlineCopy,
  AiOutlineDownload,
} from "react-icons/ai";
import { AiFillLike, AiFillDislike } from "react-icons/ai";

import { Toaster, toast } from "react-hot-toast";
import { BiSolidSend } from "react-icons/bi";
import { IoDocumentAttachSharp } from "react-icons/io5";
import { CgAttachment } from "react-icons/cg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import SkeletonLoader from "./Skeletonloader";
import { FiExternalLink } from "react-icons/fi";

const tooltipStyle = {
  position: "relative",
  display: "inline-block",
  marginRight: "10px",
};

const tooltipTextStyle = {
  visibility: "hidden",
  minWidth: "80px",
  maxWidth: "200px",
  transform: "translateX(-50%)", // Centers the tooltip horizontally
  whiteSpace: "nowrap", // Kee
  backgroundColor: "#005477",
  color: "#fff",
  textAlign: "center",
  borderRadius: "4px",
  padding: "3px",
  position: "absolute",
  zIndex: 1,
  bottom: "125%",
  left: "50%",
  marginLeft: "-40px",
  opacity: 0,
  transition: "opacity 0.3s",
  fontSize: "10px",
};

const tooltipVisibleStyle = {
  ...tooltipTextStyle,
  visibility: "visible",
  opacity: 1,
};
export default function Oldchat() {
  const [response, setResponse] = useState(null);
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [question, setQuestion] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const responseEndRef = useRef(null);
  const [popupSources, setPopupSources] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);
  const initialLoadRef = useRef(false);

  const [loading, setLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [messageId, setmessageId] = useState(null);

  const [responseHistory, setResponseHistory] = useState([]);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloading, setDownloading] = useState({});

  const [visibleTooltip, setVisibleTooltip] = useState({});
  const [chatStatuses, setChatStatuses] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  const getQueryParam = (param) => {
    const query = new URLSearchParams(location.search);
    return query.get(param);
  };
  const toggleDropdown = (id) => {
    setActiveDropdown((prev) => (prev === id ? null : id));
    console.log(id, "chat id");
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);
  const spinnerStyles = {
    width: "5rem",
    height: "5rem",
    color: "#005477",
  };

  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Date not available";
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid date";

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get("id");

    if (id) {
      setChatId(id);
      // console.log("Chat ID from query params:", id);
    }
  }, [location.search]);
  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmission = async () => {
    try {
      if (question && selectedFile) {
        console.log("Submitting both question and file.");
        await handleFileSubmit(selectedFile, question);
      } else if (question) {
        console.log("Submitting only question.");
        await handleFileSubmit(null, question); // Pass null for file
      } else if (selectedFile) {
        console.log("Submitting only file.");
        await handleFileSubmit(selectedFile, null); // Pass null for question
      } else {
        toast.error("Please enter a question or attach a file.");
      }
    } catch (err) {
      console.error("Error during submission:", err);
    }
  };
  useEffect(() => {
    responseEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [response]);

  const fetchChat = async () => {
    const id = getQueryParam("id");
    if (id) {
      setLoading(true);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/get_chat_by_id/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response, "Old chats");

        const chatData = response.data?.response_data;

        if (chatData) {
          const updatedStatuses = Array.isArray(chatData)
            ? chatData.reduce((acc, chatItem, index) => {
                if (!chatItem?.detail?.message_id) {
                  console.warn(
                    `Skipping invalid chatItem at index ${index}`,
                    chatItem
                  );
                  return acc;
                }

                const messageId = chatItem.detail.message_id;
                setmessageId(messageId);

                acc[messageId] = {
                  like: chatItem.detail.is_liked || false,
                  dislike: chatItem.detail.is_disliked || false,
                };

                return acc;
              }, {})
            : {};

          // console.log(updatedStatuses, "Updated statuses");
          setResponse(chatData || []);
          setChatStatuses(updatedStatuses);
          // console.log(chatStatuses, "Current statuses");
        } else {
          console.error("Unexpected response structure:", response.data);
          toast.error("Failed to load chat data.");
        }
      } catch (error) {
        console.error("Error fetching chat data:", error);
        toast.error("Failed to load chat data.");
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchChat();
  }, [location]);
  useEffect(() => {
    console.log(chatStatuses, "Updated chatStatuses");
  }, [chatStatuses]);
  const handleCopyClick = async (messageId) => {
    console.log(messageId, "messageId copy");

    try {
      // Call the API to get the message content by ID
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/copy_message_by_id/${messageId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (result.status === 200) {
        const copiedContent = result.data.copied_content;

        // Fallback copy function
        const fallbackCopy = (text) => {
          const textarea = document.createElement("textarea");
          textarea.value = text;
          document.body.appendChild(textarea);
          textarea.select();
          try {
            document.execCommand("copy");
            toast.success("Content copied successfully!");
          } catch (err) {
            console.error("Fallback copy failed:", err);
            toast.error("Failed to copy the content.");
          } finally {
            document.body.removeChild(textarea);
          }
        };

        // Ensure Clipboard API or use fallback
        if (
          navigator.clipboard &&
          typeof navigator.clipboard.writeText === "function"
        ) {
          navigator.clipboard
            .writeText(copiedContent)
            .then(() => {
              toast.success(
                result.data.message || "Content copied successfully!"
              );
            })
            .catch((err) => {
              console.warn("Clipboard API failed, using fallback method:", err);
              fallbackCopy(copiedContent);
            });
        } else {
          console.warn("Clipboard API not available, using fallback method.");
          fallbackCopy(copiedContent);
        }
      } else {
        toast.error("Failed to retrieve content for copying.");
      }
    } catch (error) {
      console.error("Error copying the chat:", error);
      toast.error("Failed to copy the chat.");
    }
  };

  const handleLikeClick = async (messageId) => {
    console.log(messageId, "messageId Like");
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/message/${messageId}/like`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("result:", result.data);

      if (result.status === 200) {
        // toast.success(result.data.message)
        const { is_liked } = result.data;

        setChatStatuses((prev) => ({
          ...prev,
          [messageId]: {
            like: is_liked,
          },
        }));

        await fetchChat();
      }
    } catch (error) {
      console.error("Error liking the chat:", error);
    }
  };

  const handleDislikeClick = async (messageId) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/message/${messageId}/dislike`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("result:", result.data);

      if (result.status === 200) {
        // toast.success(result.data.message)

        const { is_disliked } = result.data;
        setChatStatuses((prev) => ({
          ...prev,
          [messageId]: {
            dislike: is_disliked,
          },
        }));

        await fetchChat();
      }
    } catch (error) {
      console.error("Error liking the chat:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmission(question);
    }
  };
  const handleFileIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "text/plain",
        "image/heic",
      ];
      if (!allowedFileTypes.includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload a valid text, image, PDF, CSV, Word, or TXT file."
        );
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
      console.log("File selected:", file);

      // await handleFileSubmit(file);
    } else {
      setSelectedFile(null);
      console.log("No file selected.");
    }
  };
  const handleFileSubmit = async (file, question) => {
    console.log("Inside handleFileSubmit, file:", file, "question:", question);

    const formData = new FormData();

    // Only append file if it's defined
    if (file) {
      formData.append("file", file);
    }

    // Only append question if it's defined
    if (question) {
      formData.append("question", question);
    }

    // Append chat_id if it exists
    if (chatId) {
      formData.append("chat_id", chatId);
    }
    console.log("FormData content:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
    setIsLoader(true);
    try {
      console.log("Sending data to API...");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/recognize/api/file_analysis_v2`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("File submission response:", response.data);

      if (response.status === 200) {
        const chatIdFromResponse = response.data.chat_id || chatId;
        setChatId(chatIdFromResponse);
        navigate(`/chat?id=${chatIdFromResponse}&messageId=${activeDropdown}`, {
          replace: true,
        });
        window.dispatchEvent(new Event("sidebarUpdate"));
        responseEndRef.current?.scrollIntoView({ behavior: "smooth" });

        await fetchChat(chatIdFromResponse);

        setSelectedFile(null);
        setQuestion("");
      }
    } catch (error) {
      console.error("Error submitting file", error);

      if (error.response?.status === 401) {
        localStorage.removeItem("token");
        toast.error("Session expired. Please log in again.");
        navigate("/signin");
      } else if (error.response?.status === 403) {
        const errorMsg =
          error.response.data.message ||
          "You have used all your credits. Please upgrade to continue.";
        setErrorMessage(errorMsg);
        console.log(errorMsg);
      } else if (error.response?.status === 400) {
        const errorMsg = error.response.data.message;
        setErrorMessage(errorMsg);
        console.log(errorMsg);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } finally {
      setIsLoader(false);
    }
  };

  //  const handleFileSubmit = async (file) => {
  //   console.log("Inside handleFileSubmit, file:", file);

  //   const formData = new FormData();
  //   formData.append("file", file);

  //   if (chatId) {
  //     formData.append("chat_id", chatId);
  //   }
  //   setIsLoader(true);
  //   try {
  //     console.log("Sending file to API...");
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/recognize/api/file_analysis`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     console.log("File submission response:", response.data);

  //     if (response.status === 200) {
  //       const chatIdFromResponse = response.data.chat_id || chatId;
  //       setChatId(chatIdFromResponse);
  //       navigate(`/chat?id=${chatIdFromResponse}`, { replace: true });
  //       window.dispatchEvent(new Event("sidebarUpdate"));
  //       responseEndRef.current?.scrollIntoView({ behavior: "smooth" });

  //       // Fetch updated chat
  //       await fetchChat(chatIdFromResponse);

  //       // Reset the selected file after successful request
  //       setSelectedFile(null);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting file", error);

  //     if (error.response?.status === 401) {
  //       localStorage.removeItem("token");
  //       toast.error("Session expired. Please log in again.");
  //       navigate("/signin");
  //     } else if (error.response?.status === 403) {
  //       const errorMsg = error.response.data.message || "You have used all your credits. Please upgrade to continue.";
  //       setErrorMessage(errorMsg);
  //       console.log(errorMsg);
  //     } else {
  //       toast.error("Something went wrong. Please try again.");
  //     }

  //   }
  //   finally {
  //     setIsLoader(false);
  //   }
  // };
  const handleDownloadClick = async (messageId) => {
    console.log("download message id", messageId);
    const trimmedMessageId = messageId.toString().trim();
    const id = getQueryParam("id");
    if (id && messageId) {
      setDownloading((prev) => ({ ...prev, [messageId]: true }));
      try {
        const url = `${process.env.REACT_APP_API_URL}/user/api/get_chat_summary/${id}/${trimmedMessageId}`;
        const result = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const pdfLink = result.data.result;
        // toast.success("Chat summary PDF created and downloaded successfully!");

        const link = document.createElement("a");
        link.href = pdfLink;
        link.setAttribute("download", `Chat_Summary_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the PDF:", error);
      } finally {
        setDownloading((prev) => ({ ...prev, [messageId]: false }));
      }
    }
  };

  const handleViewMoreClick = (messageId) => {
    // Find the specific message by ID and extract sources
    const message = response.find(
      (item) => item.detail.message_id === messageId
    );

    if (message) {
      const specificSources = message.detail.response?.sources || [];

      // Set the popup sources and open the popup
      setPopupSources(specificSources);
      setIsPopupOpen(true);
    } else {
      console.log(`Message with ID ${messageId} not found.`);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false);
    }
  };
  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = "hidden"; // Disable body scroll
    } else {
      document.body.style.overflow = ""; // Enable body scroll
    }
  }, [isPopupOpen]);
  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);
  if (errorMessage) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px 30px",
            borderRadius: "10px",
            boxShadow:
              "0 4px 10px rgba(0, 0, 0, 0.1), 0 8px 20px rgba(0, 0, 0, 0.15), 0 12px 30px rgba(0, 0, 0, 0.2)",
            border: "1px solid #e0e0e0",
            maxWidth: "500px",
            textAlign: "center",
          }}
        >
          <div className=" text-center mt-10 p-4 text-lg  font-semibold">
            {errorMessage}
            <div className="pricing-two__single wow fadeInUp">
              <div className="flex justify-center items-center mt-4">
                <Link to="/upgrade">
                  <button
                    style={{ backgroundColor: "#111827" }}
                    className="btn btn--septenary"
                  >
                    Upgrade
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!response) {
    return (
      <div
        className={`flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 ${
          isSidebarOpen ? "md:ml-[350px] md:w-[calc(100%-350px)]" : "w-full"
        }`}
      >
        <div
          className="flex flex-col items-center"
          style={{ minHeight: "100vh", padding: "10px" }}
        >
          <SkeletonLoader />
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />

      <div
        className={`flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 ${
          isSidebarOpen ? "md:ml-[350px] md:w-[calc(100%-350px)]" : "w-full"
        }`}
      >
        <div
          className="flex flex-col items-center"
          style={{ minHeight: "100vh", padding: "10px" }}
        >
          {isLoader ? (
            <SkeletonLoader />
          ) : (
            <div className="mb-5   w-full h-full">
              {response?.map((chatItem, index, chatDetails) => {
                const messageId = chatItem?.detail?.message_id;

                return (
                  <div>
                    <div key={`${index}-${messageId}`} className="chat-card">
                      <div>
                        {/* <header
            className="flex items-center justify-center w-full p-4 border-b border-gray-300" style={{width:"90%"}}
          >
            <p className="font-semibold text-lg">{chatItem?.detail?.response?.headertitle|| ""}</p>
          </header> */}
                        <header
                          className={`flex items-center justify-center w-full p-4 ${
                            chatItem?.detail?.response?.headertitle
                              ? "border-b border-gray-300"
                              : ""
                          }`}
                          style={{ width: "90%" }}
                        >
                          {chatItem?.detail?.response?.headertitle && (
                            <p className="font-semibold text-lg">
                              {chatItem?.detail?.response?.headertitle}
                            </p>
                          )}
                        </header>
                        <div className="lg:pl-5 md:pl-5 mb-5">
                          <div>
                            {chatItem?.detail?.response?.title ===
                            "Nothing found" ? (
                              <div className="flex justify-between">
                                <div>
                                  <div className="flex gap-1 mt-5 md:pl-20 lg:pl-20 ">
                                    <div style={{ marginTop: "2px" }}>
                                      <CiClock2 />
                                    </div>
                                    <div>
                                      <p className="font-semibold text-sm">
                                        {chatItem.detail?.datetime || ""}
                                      </p>
                                    </div>
                                  </div>
                                  <h3 className="font-semibold mt-5 md:pl-20 lg:pl-20 ">
                                    {chatItem?.detail?.response?.title || ""}
                                  </h3>

                                  <div className="flex gap-2 mt-5  md:pl-20 lg:pl-20 ">
                                    <div className="mt-2">
                                      <MdWorkspacesOutline />
                                    </div>
                                    <p className=" text-lg font-semibold">
                                      Sources
                                    </p>
                                  </div>

                                  <div
                                    className="mt-5 md:pl-20 lg:pl-20"
                                    style={{ width: "80%" }}
                                  >
                                    {chatItem?.detail?.response?.sources
                                      ?.length > 0 ? (
                                      <div
                                        className="flex justify-between flex-col md:flex-row"
                                        style={{
                                          flexDirection:
                                            window.innerWidth <= 1024
                                              ? "column"
                                              : "row",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        <div
                                          className="flex gap-2"
                                          style={{
                                            width:
                                              window.innerWidth <= 1024
                                                ? "100%"
                                                : "85%",
                                          }}
                                        >
                                          {chatItem?.detail?.response?.sources
                                            .slice(0, 1)
                                            .map((sourceItem, index) => (
                                              <div
                                                key={index}
                                                style={{
                                                  width: "90%",
                                                  backgroundColor: "#E8E9E8",
                                                  borderRadius: "10px",
                                                }}
                                                className="p-2"
                                              >
                                                <div className="flex">
                                                  <div>
                                                    <img
                                                      src={
                                                        sourceItem.logo || ""
                                                      }
                                                      alt={
                                                        sourceItem.name || ""
                                                      }
                                                      style={{
                                                        width: "80%",
                                                        height: "80%",
                                                        objectFit: "contain",
                                                      }}
                                                    />
                                                  </div>
                                                  <div>
                                                    <p
                                                      style={{
                                                        fontWeight: "bold",
                                                        wordWrap: "break-word",
                                                      }}
                                                    >
                                                      {sourceItem.name || ""}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="">
                                                  <p
                                                    style={{
                                                      fontSize: "0.875rem",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    <a
                                                      href={sourceItem.link}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      style={{
                                                        color: "blue",
                                                        textDecoration:
                                                          "underline",
                                                        wordBreak: "break-all",
                                                        textTransform: "none",
                                                      }}
                                                    >
                                                      {sourceItem.link || ""}
                                                    </a>
                                                  </p>
                                                  <p
                                                    style={{
                                                      fontSize: "0.875rem",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    {sourceItem.detail || ""}
                                                  </p>
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                        <div
                                          className="p-2"
                                          style={{
                                            width:
                                              window.innerWidth <= 1024
                                                ? "100%"
                                                : "18%",
                                            backgroundColor: "#E8E9E8",
                                            borderRadius: "10px",
                                            marginTop:
                                              window.innerWidth <= 1024
                                                ? "10px"
                                                : "0",
                                          }}
                                        >
                                          <div
                                            className="flex gap-1 justify-center"
                                            style={{ fontSize: "20px" }}
                                          >
                                            {chatDetails?.detail?.response?.sources
                                              .slice(0, 3)
                                              .map((source, idx) => (
                                                <a
                                                  href={source.link}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  key={idx}
                                                >
                                                  <img
                                                    src={source.logo || ""}
                                                    alt={source.name || ""}
                                                    style={{
                                                      width: "70px",
                                                      height: "70px",
                                                      marginRight: "10px",
                                                    }}
                                                  />
                                                </a>
                                              ))}
                                          </div>

                                          <div className="mt-3">
                                            <a
                                              className="text-sm"
                                              onClick={handleViewMoreClick}
                                              style={{
                                                cursor: "pointer",
                                                color: "white",
                                                textDecoration: "none",
                                                display: "block",
                                                textAlign: "center",
                                                padding: "10px 15px",
                                                border: "none",
                                                borderRadius: "10px",
                                                backgroundColor: "#007bff",
                                                fontWeight: "bold",
                                                boxShadow:
                                                  "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseEnter={(e) => {
                                                e.target.style.backgroundColor =
                                                  "#0056b3";
                                                e.target.style.transform =
                                                  "scale(1.05)";
                                              }}
                                              onMouseLeave={(e) => {
                                                e.target.style.backgroundColor =
                                                  "#007bff";
                                                e.target.style.transform =
                                                  "scale(1)";
                                              }}
                                            >
                                              View More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <p></p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="flex justify-between">
                                  <div>
                                    {chatItem?.detail?.response?.datetime && (
                                      <div className="flex gap-1 mt-5">
                                        <div style={{ marginTop: "2px" }}>
                                          <CiClock2 />
                                        </div>
                                        <div>
                                          <p className="font-semibold text-sm">
                                            {chatItem.detail.response.datetime}
                                          </p>
                                        </div>
                                      </div>
                                    )}

                                    <h3
                                      className="font-semibold mt-5 
                                 "
                                    >
                                      {chatItem?.detail?.response?.title || ""}
                                    </h3>
                                    {Array.isArray(
                                      chatItem?.detail?.response?.sources
                                    ) &&
                                      chatItem.detail.response.sources.length >
                                        0 && (
                                        <div>
                                          <div className="flex gap-2 mt-5">
                                            <div className="mt-2">
                                              <MdWorkspacesOutline />
                                            </div>
                                            <p className="text-lg font-semibold">
                                              Sources
                                            </p>
                                          </div>

                                          <div
                                            className="mt-5"
                                            style={{ width: "80%" }}
                                          >
                                            <div
                                              className="flex justify-between flex-col md:flex-row"
                                              style={{
                                                alignItems: "flex-start",
                                                gap: "10px",
                                              }}
                                            >
                                              <div className="flex gap-2 flex-1">
                                                {chatItem.detail.response.sources
                                                  .slice(0, 1)
                                                  .map((sourceItem, index) => (
                                                    <div
                                                      key={index}
                                                      style={{
                                                        flex: 1,
                                                        backgroundColor:
                                                          "#E8E9E8",
                                                        borderRadius: "10px",
                                                        padding: "10px",
                                                        minWidth: "200px",
                                                        maxWidth: "90%",
                                                      }}
                                                      className="p-2"
                                                    >
                                                      <div className="flex">
                                                        <div>
                                                          <img
                                                            src={
                                                              sourceItem.logo ||
                                                              ""
                                                            }
                                                            alt={
                                                              sourceItem.name ||
                                                              ""
                                                            }
                                                            style={{
                                                              width: "80px",
                                                              height: "80px",
                                                              objectFit:
                                                                "contain",
                                                            }}
                                                          />
                                                        </div>
                                                        <div
                                                          style={{
                                                            flexGrow: 1,
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          <p
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                              wordWrap:
                                                                "break-word",
                                                            }}
                                                          >
                                                            {sourceItem.name ||
                                                              ""}
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize:
                                                                "0.875rem",
                                                              wordWrap:
                                                                "break-word",
                                                            }}
                                                          >
                                                            <a
                                                              href={
                                                                sourceItem.link ||
                                                                ""
                                                              }
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                              style={{
                                                                color: "blue",
                                                                textDecoration:
                                                                  "underline",
                                                                wordBreak:
                                                                  "break-all",
                                                                textTransform:
                                                                  "none",
                                                              }}
                                                            >
                                                              {sourceItem.link ||
                                                                ""}
                                                            </a>
                                                          </p>
                                                          <p
                                                            style={{
                                                              fontSize:
                                                                "0.875rem",
                                                              wordWrap:
                                                                "break-word",
                                                            }}
                                                          >
                                                            {sourceItem.detail ||
                                                              ""}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                              </div>

                                              <div
                                                className="p-2"
                                                style={{
                                                  backgroundColor: "#E8E9E8",
                                                  borderRadius: "10px",
                                                  padding: "10px",
                                                  minWidth: "150px",
                                                  maxWidth: "200px",
                                                }}
                                              >
                                                <div
                                                  className="flex gap-1 justify-center"
                                                  style={{ fontSize: "20px" }}
                                                >
                                                  {chatItem.detail.response.sources
                                                    .slice(0, 3)
                                                    .map((source, idx) => (
                                                      <a
                                                        href={source.link || ""}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        key={idx}
                                                      >
                                                        <img
                                                          src={
                                                            source.logo || ""
                                                          }
                                                          alt={
                                                            source.name || ""
                                                          }
                                                          style={{
                                                            width: "25px",
                                                            height: "25px",
                                                            marginRight: "10px",
                                                          }}
                                                        />
                                                      </a>
                                                    ))}
                                                </div>

                                                <div className="mt-3">
                                                  <a
                                                    className="text-sm"
                                                    onClick={() =>
                                                      handleViewMoreClick(
                                                        messageId
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "white",
                                                      textDecoration: "none",
                                                      display: "block",
                                                      textAlign: "center",
                                                      padding: "10px 15px",
                                                      border: "none",
                                                      borderRadius: "10px",
                                                      backgroundColor:
                                                        "#007bff",
                                                      fontWeight: "bold",
                                                      boxShadow:
                                                        "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                      transition:
                                                        "all 0.3s ease",
                                                    }}
                                                    onMouseEnter={(e) => {
                                                      e.target.style.backgroundColor =
                                                        "#0056b3";
                                                      e.target.style.transform =
                                                        "scale(1.05)";
                                                    }}
                                                    onMouseLeave={(e) => {
                                                      e.target.style.backgroundColor =
                                                        "#007bff";
                                                      e.target.style.transform =
                                                        "scale(1)";
                                                    }}
                                                  >
                                                    View More
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                    <div
                                      className="mt-3  "
                                      style={{ width: "80%" }}
                                    >
                                      <div className="flex gap-2 mt-4">
                                        <img
                                          className="mt-1"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          src="/assets/images/logoicon.png"
                                          alt="Logo"
                                        />
                                        <p className="text-lg font-semibold">
                                          Answer
                                        </p>
                                      </div>

                                      <p className="mt-2">
                                        {chatItem?.detail?.response?.answer ||
                                          ""}
                                      </p>
                                      {Array.isArray(
                                        chatItem?.detail?.response?.description
                                      ) &&
                                        chatItem?.detail?.response?.description.map(
                                          (desc, idx) => (
                                            <div
                                              key={`description-${idx}`}
                                              className="mt-4"
                                            >
                                              <h4 className="font-bold capitalize">
                                                {desc.type}
                                              </h4>
                                              {Array.isArray(desc.details) &&
                                                desc.details.map(
                                                  (detail, detailIdx) => (
                                                    <div
                                                      key={`detail-${idx}-${detailIdx}`}
                                                      className="mt-2"
                                                    >
                                                      <p>
                                                        <strong>
                                                          {detail.name || ""}:
                                                        </strong>{" "}
                                                        {detail.content || ""}
                                                      </p>
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                          )
                                        )}
                                    </div>

                                    {Array.isArray(
                                      chatItem?.detail?.response?.references
                                    ) &&
                                      chatItem.detail.response.references
                                        .length > 0 && (
                                        <div>
                                          <div
                                            className="flex gap-2"
                                            style={{
                                              marginTop: "30px",
                                            }}
                                          >
                                            <MdOutlineFormatIndentIncrease className="mt-1" />
                                            <p className="font-semibold">
                                              References
                                            </p>
                                          </div>

                                          {chatItem.detail.response.references.map(
                                            (source, idx) => (
                                              <div
                                                key={`reference-${idx}`}
                                                className="mt-4 md:pl-20 lg:pl-20"
                                              >
                                                <div className="flex items-center space-x-2">
                                                  <a
                                                    href={source.href}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="flex items-center text-blue-600 hover:underline"
                                                  >
                                                    {source.name ||
                                                      "Default Title"}
                                                    <FiExternalLink className="ml-1" />
                                                  </a>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </>
                            )}

                            <div
                              className="mt-4"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                                                                                    {chatItem?.detail?.response?.references && (

                              <div
                                className="relative inline-block"
                                style={tooltipStyle}
                                onMouseEnter={() =>
                                  setVisibleTooltip({
                                    [messageId]:
                                      "Explore Doctors, Pharmacies, and Labs",
                                  })
                                }
                                onMouseLeave={() =>
                                  setVisibleTooltip({
                                    [messageId]: "",
                                  })
                                }
                              >
                                <button
                                  onClick={() => toggleDropdown(messageId)}
                                  className="flex items-center gap-2 p-2 rounded text-gray-500 hover:bg-gray-100"
                                >
                                  <BiDotsHorizontalRounded size={20} />
                                  <span
                                    style={
                                      visibleTooltip[messageId] ===
                                      "Explore Doctors, Pharmacies, and Labs"
                                        ? tooltipVisibleStyle
                                        : tooltipTextStyle
                                    }
                                  >
                                    Explore Doctors, Pharmacies, and Labs
                                  </span>
                                </button>

                                {activeDropdown === messageId && (
                                  <div
                                    ref={dropdownRef}
                                    className="absolute top-full mt-2 text-sm w-54 bg-white border rounded shadow-lg p-3"
                                    style={{
                                      position: "absolute",
                                      zIndex: 50,
                                    }}
                                  >
                                    <Link
                                      to={`/nearest-doctor?id=${chatId}&messageId=${activeDropdown}`}
                                    >
                                      <div className="flex items-center gap-2 mb-2">
                                        <span role="img" aria-label="doctor">
                                          🩺
                                        </span>
                                        <span className="text-sm ">Doctor</span>
                                      </div>
                                    </Link>
                                    <Link
                                      to={`/nearest-pharmacy?id=${chatId}&messageId=${activeDropdown}`}
                                    >
                                      <div className="flex items-center gap-2 mb-2">
                                        <span role="img" aria-label="pharmacy">
                                          💊
                                        </span>
                                        <span className="text-sm ">
                                          Pharmacy
                                        </span>
                                      </div>
                                    </Link>
                                    <Link
                                      to={`/nearest-laboratory?id=${chatId}&messageId=${activeDropdown}`}
                                    >
                                      <div className="flex items-center gap-2">
                                        <span
                                          role="img"
                                          aria-label="laboratory"
                                        >
                                          🧪
                                        </span>

                                        <span className="text-sm ">
                                          Laboratory{" "}
                                        </span>
                                      </div>
                                    </Link>
                                    <Link to={`/feedback?id=${chatId}`}>
                                      <div className="flex items-center gap-2">
                                        <span role="img" aria-label="feedback">
                                          📝
                                        </span>
                                        <span className="text-sm ">
                                          Feedback
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                )}
                              </div>
                                                                                    )}
                              <div
                                style={tooltipStyle}
                                onMouseEnter={() =>
                                  setVisibleTooltip({ [messageId]: "like" })
                                }
                                onMouseLeave={() =>
                                  setVisibleTooltip({ [messageId]: null })
                                }
                              >
                                <button
                                  className={`flex items-center gap-2 p-2 rounded hover:bg-gray-100 ${
                                    chatStatuses[messageId]?.like
                                      ? "text-blue-500"
                                      : "text-gray-500"
                                  }`}
                                  onClick={() => handleLikeClick(messageId)}
                                >
                                  {chatStatuses[messageId]?.like ? (
                                    <AiFillLike
                                      size={20}
                                      className="text-blue-500"
                                    />
                                  ) : (
                                    <AiOutlineLike
                                      size={20}
                                      className="text-gray-500"
                                    />
                                  )}
                                </button>
                                <span
                                  style={
                                    visibleTooltip[messageId] === "like"
                                      ? tooltipVisibleStyle
                                      : tooltipTextStyle
                                  }
                                >
                                  Like
                                </span>
                              </div>

                              <div
                                style={tooltipStyle}
                                onMouseEnter={() =>
                                  setVisibleTooltip({ [messageId]: "dislike" })
                                }
                                onMouseLeave={() =>
                                  setVisibleTooltip({ [messageId]: null })
                                }
                              >
                                <button
                                  className={`flex items-center gap-2 p-2 rounded hover:bg-gray-100 ${
                                    chatStatuses[messageId]?.dislike
                                      ? "text-blue-500"
                                      : "text-gray-500"
                                  }`}
                                  onClick={() => handleDislikeClick(messageId)}
                                >
                                  {chatStatuses[messageId]?.dislike ? (
                                    <AiFillDislike
                                      size={20}
                                      className="text-blue-500"
                                    />
                                  ) : (
                                    <AiOutlineDislike
                                      size={20}
                                      className="text-gray-500"
                                    />
                                  )}
                                </button>
                                <span
                                  style={
                                    visibleTooltip[messageId] === "dislike"
                                      ? tooltipVisibleStyle
                                      : tooltipTextStyle
                                  }
                                >
                                  Dislike
                                </span>
                              </div>
                              <div
                                style={tooltipStyle}
                                onMouseEnter={() =>
                                  setVisibleTooltip({ [messageId]: "copy" })
                                }
                                onMouseLeave={() =>
                                  setVisibleTooltip({ [messageId]: null })
                                }
                              >
                                <button
                                  className="flex items-center gap-2 p-2 rounded text-gray-500 hover:bg-gray-100"
                                  onClick={() => handleCopyClick(messageId)}
                                >
                                  <AiOutlineCopy size={20} />
                                </button>
                                <span
                                  style={
                                    visibleTooltip[messageId] === "copy"
                                      ? tooltipVisibleStyle
                                      : tooltipTextStyle
                                  }
                                >
                                  Copy
                                </span>
                              </div>

                              <div
                                style={tooltipStyle}
                                onMouseEnter={() =>
                                  setVisibleTooltip({ [messageId]: "download" })
                                }
                                onMouseLeave={() => setVisibleTooltip({})}
                              >
                                <button
                                  className="flex items-center gap-2 p-2 text-gray-500 rounded hover:bg-gray-100"
                                  onClick={() => handleDownloadClick(messageId)}
                                >
                                  <AiOutlineDownload size={20} />
                                </button>
                                <span
                                  style={
                                    visibleTooltip[messageId] === "download"
                                      ? tooltipVisibleStyle
                                      : tooltipTextStyle
                                  }
                                >
                                  {downloading[messageId]
                                    ? "Generating your report..."
                                    : "Download"}
                                </span>
                              </div>
                            </div>
                                                     
                            <hr
                              style={{
                                borderColor: "#e8e9e8",
                                borderWidth: "1px",
                                marginTop: "10px",
                                width: "90%",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div ref={responseEndRef} />
          <div
            className="fixed bottom-0 left-0 w-full flex justify-center mt-8 items-center p-4 "
            style={{ zIndex: 10 }}
          >
            <div>
              <div className="mt-5">
                <div
                  className="border relative p-2"
                  style={{
                    borderRadius: "30px",
                    overflow: "hidden",
                    height: "70px",
                    backgroundColor: "white",
                    boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <textarea
                    ref={textareaRef}
                    className="  lg:w-[600px] md:w-[400px] w-[300px]  rounded p-3  text-sm text-dark resize-none "
                    style={{
                      color: "black",
                      overflowY: "auto",
                      borderRadius: "5px",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      backgroundColor: "white",
                      textTransform: "none",
                    }}
                    placeholder="Ask Question"
                    required
                    value={question}
                    onChange={handleQuestionChange}
                    onKeyDown={handleKeyDown}
                    rows={1}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <button
                    type="button"
                    className="absolute right-14 bottom-3.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl"
                    style={{ color: "#4569e7" }}
                    onClick={() => fileInputRef.current.click()}
                  >
                    {selectedFile ? (
                      <IoDocumentAttachSharp />
                    ) : (
                      <CgAttachment style={{ color: "#C5C6C7" }} />
                    )}
                  </button>

                  <button
                    type="submit"
                    className="absolute right-4 bottom-3.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl"
                    style={{ marginTop: "-10px" }}
                    // onClick={() => handleQuestionSubmit(question)}
                    onClick={handleSubmission}
                  >
                    <BiSolidSend
                      className={
                        question || selectedFile
                          ? "text-gray-900"
                          : "text-gray-300"
                      }
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Popup */}
        {isPopupOpen && (
          <div
            ref={popupRef}
            className="fixed top-0 right-0 border h-full bg-white shadow-lg z-50"
            style={{
              width: "500px",
              transition: "transform 0.3s",
              transform: "translateX(0)",
            }}
          >
            <div className="p-4 flex justify-between items-center border-b">
              <div>
                <p className="font-semibold text-xl">
                  Sources ({popupSources.length})
                </p>
                <p className="text-lg text-gray-600">
                  {popupSources.length > 0 ? popupSources[0]?.title : "Sources"}
                </p>
              </div>
              <button
                style={{ fontSize: "30px" }}
                onClick={handleClosePopup}
                className="text-gray-500 hover:text-black"
              >
                &times;
              </button>
            </div>
            <div className="p-4 overflow-y-auto" style={{ maxHeight: "80%" }}>
              {popupSources.length > 0 ? (
                popupSources.map((source, idx) => (
                  <div
                    key={idx}
                    className="mb-3 p-3"
                    style={{
                      backgroundColor: "#F9FAFB",
                      border: "1px solid #E5E7EB",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="flex items-center gap-3 mb-2">
                      <span className="font-bold text-lg">{idx + 1}.</span>
                      <div className="flex items-center gap-2">
                        <span className="font-semibold">{source.name}</span>
                        <a
                          href={source.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={source.logo}
                            alt={source.name}
                            style={{ width: "25px", height: "25px" }}
                          />
                        </a>
                      </div>
                    </div>
                    <p className="text-sm text-gray-700">{source.detail}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No sources available.</p>
              )}
            </div>
          </div>
        )}
        {/* {isPopupOpen && (
        <div
        ref={popupRef}
          className="fixed top-0 right-0 border h-full bg-white shadow-lg z-50"
          style={{
            width: "500px",
            transition: "transform 0.3s",
            transform: "translateX(0)",
          }}
        >
          <div className="p-4 flex justify-between items-center border-b">
            <div>
              <p className="font-semibold text-xl">
              Sources ({popupSources.length})
              </p>
              <p className="text-lg text-gray-600">{response?.detail?.response?.title}</p>
            </div>
            <button
              style={{ fontSize: "30px" }}
              onClick={handleClosePopup}
              className="text-gray-500 hover:text-black"
            >
              &times;
            </button>
          </div>
          <div className="p-4 overflow-y-auto" style={{ maxHeight: "80%" }}>
            {popupSources.length > 0 ? (
              popupSources.map((source, idx) => (
                <div
                  key={idx}
                  className="mb-3 p-3"
                  style={{
                    backgroundColor: "#F9FAFB",
                    border: "1px solid #E5E7EB",
                    borderRadius: "8px",
                  }}
                >
                  <div className="flex items-center gap-3 mb-2">
                    <span className="font-bold text-lg">{source.number}.</span>
                    <div className="flex items-center gap-2">
                      <span className="font-semibold">{source.name}</span>
                      <a
                        href={source.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={source.logo}
                          alt={source.name}
                          style={{ width: "25px", height: "25px" }}
                        />
                      </a>
                    </div>
                  </div>
                  <p className="text-sm text-gray-700">{source.detail}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No sources available.</p>
            )}
          </div>
        </div>
      )} */}

        {/* {isPopupOpen && (
                            <div
                              className="fixed top-0 right-0 border h-full bg-white shadow-lg z-50"
                              style={{
                                width: "500px",
                                transition: "transform 0.3s",
                                transform: "translateX(0)",
                              }}
                            >
                              <div className="p-4 flex justify-between items-center border-b">
                                <div>
                                  <p className="font-semibold text-xl">
                                    Sources {response?.detail?.response?.source_count}
                                  </p>
                                  <p>{response?.title}</p>{" "}
                                </div>
                                <div>
                                  <button
                                    style={{ fontSize: "30px" }}
                                    onClick={handleClosePopup}
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                              <div className="p-4">
                                {response.detail?.response?.sources.map((source, idx) => (
                                  <div
                                    className="mb-3 border p-2"
                                    style={{
                                      width: "auto",
                                      backgroundColor: "#E8E9E8",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <p className="flex gap-2">
                                      <div className=" font-semibold">
                                        {source.number} .
                                      </div>
                                      <div className="flex gap-2 font-semibold">
                                        {source.name}
                                        <a
                                          href={source.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          key={idx}
                                        >
                                          <img
                                            key={idx}
                                            src={source.logo}
                                            alt={source.name}
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                              marginRight: "10px",
                                            }}
                                          />
                                        </a>
                                       
                                      </div>
                                    </p>
                                    <p>{source.detail}</p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )} */}
      </div>
    </div>
  );
}
