

import React, { useState, useEffect } from "react";
import axios from "axios";
import ChatHeader from "./Chatheader";
import { FiExternalLink } from "react-icons/fi";
import DropdownSkeleton from "./DropdownSkeleton";
import { StarIcon,GlobeAltIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";

export default function Doctors() {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTabs, setActiveTabs] = useState({});
  const [locationDenied, setLocationDenied] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const messageId = queryParams.get("messageId");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [userDeniedGeolocation, setUserDeniedGeolocation] = useState(false);
const [isRequestSent, setIsRequestSent] = useState(false); // Track API request status
useEffect(() => {
  const getUserLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setUserDeniedGeolocation(false);
          setLocationDenied(false);
        },
        async (error) => {
          if (error.code === 1) { 
            setUserDeniedGeolocation(true);
            setLocationDenied(true);
            setLoading(false);
            return;
          }
          try {
            setLoading(true);
            const ipResponse = await axios.get("https://api64.ipify.org?format=json");
            const locationResponse = await axios.get(`https://ipapi.co/${ipResponse.data.ip}/json/`);
            if (locationResponse.data.latitude && locationResponse.data.longitude) {
              setLatitude(locationResponse.data.latitude);
              setLongitude(locationResponse.data.longitude);
              setLocationDenied(false);
            } else {
              setLocationDenied(true);
            }
          } catch (error) {
            console.error("Error fetching IP location:", error);
            setLocationDenied(true);
          } finally {
            setLoading(false);
          }
        }
      );
    } else {
      setUserDeniedGeolocation(true);
      setLocationDenied(true);
      setLoading(false);
    }
  };

  getUserLocation();
}, []);


 
  // useEffect(() => {
  //   const getUserLocation = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //                       setUserDeniedGeolocation(false);

  //           setLatitude(position.coords.latitude);
  //           setLongitude(position.coords.longitude);
  //           setLocationDenied(false);
  //         },
  //         async () => {
  //           // Agar location deny ho jaye toh IP se location fetch karni hai
  //           setLoading(true);
  //           try {
  //             const ipResponse = await axios.get("https://api64.ipify.org?format=json");
  //             const ip = ipResponse.data.ip;
  //             console.log(ip,"ip")
  //             const locationResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
  //             console.log(locationResponse ,"ip")
  //             setLatitude(locationResponse.data.latitude);
  //             setLongitude(locationResponse.data.longitude);
  //             setLocationDenied(false);
  //           } catch (error) {

  //             console.error("Error fetching IP location:", error);
  //             setLocationDenied(true);
  //           } finally {
  //             setLoading(false);
  //           }
  //         }
  //       );
  //     } else {
  //       setLocationDenied(true);
  //       setLoading(false);
  //     }
  //   };

  //   getUserLocation();
  // }, []);
  useEffect(() => {
    const fetchDoctors = async () => {
      if (!messageId || locationDenied || latitude === null || longitude === null) {
        setLoading(false);
        return;
      }
            setIsRequestSent(true);

      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/google_doctor_search/${messageId}/?lat=${latitude}&lng=${longitude}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const url =  `${process.env.REACT_APP_API_URL}/user/api/google_doctor_search/${messageId}/?lat=${latitude}&lng=${longitude}`
        console.log(url,"doctors")

console.log(response,"doctors")
        if (response.data && Array.isArray(response.data)) {
          setDoctors(response.data);
          const defaultTabs = response.data.reduce((acc, doctor) => {
            acc[doctor.doctor_name] = "overview";
            return acc;
          }, {});
          setActiveTabs(defaultTabs);
        } else {
          setDoctors([]);
        }
      } catch (error) {
        console.error("Error fetching doctors:", error);
        setDoctors([]);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctors();
  }, [messageId, latitude, longitude, locationDenied]);

  const handleTabClick = (doctorName, tab) => {
    setActiveTabs((prev) => ({ ...prev, [doctorName]: tab }));
  };
// Star rating component
const RatingStars = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;

  return (
    <div className="flex items-center">
      {[...Array(5)].map((_, i) => (
        <StarIcon
          key={i}
          className={`w-5 h-5 ${
            i < fullStars
              ? 'text-yellow-400'
              : hasHalfStar && i === fullStars
              ? 'text-yellow-400'
              : 'text-gray-300'
          }`}
        />
      ))}
      <span className="ml-2 text-gray-600">({rating})</span>
    </div>
  );
};

// Review component
const Review = ({ review }) => (
 <div
    className="border-t pt-4 mt-4 "
    
  >
 
    <div className="flex items-start ">   
      <img
        src={review.profile_photo_url || 'https://via.placeholder.com/40'}
        alt={review.author_name}
        className="w-10 h-10 rounded-full mr-3"
      />
      <div>
        <div className="flex items-center">
          <p className="font-semibold">{review.author_name}</p>
          <span className="mx-2">•</span>
          <RatingStars rating={review.rating} />
        </div>
        <p className="text-gray-600 text-sm">{review.relative_time_description}</p>
        <p className="mt-1">{review.text}</p>
      </div>
    </div>
  </div>
);
  return (
    <div className={`flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 ${
      isSidebarOpen ? "md:ml-[250px] md:w-[calc(100%-250px)] lg:ml-[250px] lg:w-[calc(100%-250px)] xl:ml-[350px] xl:w-[calc(100%-350px)]" : "w-full"
    }`}>
      <div>
        <ChatHeader />
      </div>
    
      <h1 className="text-2xl md:text-3xl font-bold text-center my-4 md:my-8">Recommended Doctors</h1>
    
      {!isRequestSent ? ( 
 <div className="col-span-full text-center p-4 mt-4">
              {/*<GlobeAltIcon className="w-12 h-12 mx-auto text-blue-500 mb-4" />*/}
              <h3 className="text-xl font-semibold mb-2">
                Allow Location Access
              </h3>
              <p className="text-gray-600">
                Please enable location permissions to find doctors near you.
              </p>
            </div>    ) :loading ? (
        <DropdownSkeleton />
      ) : (
        <div className="container mx-auto" style={{ width: "90%" }}>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6">
             {userDeniedGeolocation && doctors.length === 0 ? (
            <div className="col-span-full text-center p-4 mt-4">
              {/*<GlobeAltIcon className="w-12 h-12 mx-auto text-blue-500 mb-4" />*/}
              <h3 className="text-xl font-semibold mb-2">
                Allow Location Access
              </h3>
              <p className="text-gray-600">
                Please enable location permissions to find doctors near you.
              </p>
            </div>
          ) :Array.isArray(doctors) && doctors.length > 0 ? (
              doctors.map((doctor, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
                >
                  <div className="p-4 md:p-6">
                    {/* Doctor Info Section */}
                    <div className="flex items-start mb-4">
                      {doctor.photo_url ? (
                        <img
                          src={doctor.photo_url}
                          alt={doctor.doctor_name}
                          className="w-16 h-16 md:w-20 md:h-20 rounded-full object-cover mr-3 md:mr-4 flex-shrink-0"
                        />
                      ) : (
                        <div className="w-16 h-16 md:w-20 md:h-20 rounded-full bg-gray-300 flex items-center justify-center text-gray-500 mr-3 md:mr-4 flex-shrink-0">
                          <span className="text-xs font-semibold text-center">No Image</span>
                        </div>
                      )}
                      <div className="flex flex-col flex-1 min-w-0">
                        <h2 className="text-lg md:text-xl font-bold ">{doctor.doctor_name}</h2>
                        <p className="text-sm md:text-base text-gray-600 ">{doctor.phone_number}</p>
                        <div className="mt-1">
                          <RatingStars rating={doctor.ratings} />
                        </div>
                      </div>
                    </div>
    
                    {/* Tabs Section */}
                    <div className="flex flex-wrap gap-2 md:gap-4 mb-4 md:mb-6">
                      <button
                        className={`px-3 py-1 md:px-4 md:py-2 rounded text-sm md:text-base ${
                          activeTabs[doctor.doctor_name] === "overview"
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handleTabClick(doctor.doctor_name, "overview")}
                      >
                        Overview
                      </button>
                      <button
                        className={`px-3 py-1 md:px-4 md:py-2 rounded text-sm md:text-base ${
                          activeTabs[doctor.doctor_name] === "reviews"
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handleTabClick(doctor.doctor_name, "reviews")}
                      >
                        Reviews
                      </button>
                      <a 
                        href={doctor.website} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="ml-auto"
                      >
                        <FiExternalLink className="w-5 h-5 md:w-7 md:h-7 text-blue-600 hover:text-blue-800 transition-colors" />
                      </a>
                    </div>
    
                    {/* Content Section */}
                    {activeTabs[doctor.doctor_name] === "overview" ? (
                      <>
                        <div className="mb-4">
                          <p className="font-semibold text-sm md:text-base">📍 Location</p>
                          <p className="text-gray-600 text-sm md:text-base ">{doctor.location.address}</p>
                          {/* <p className="text-gray-600 text-sm md:text-base">
                            {doctor.location.city}, {doctor.location.state}
                          </p> */}
                        </div>
                        <div className="mb-4">
                          <p className="font-semibold text-sm md:text-base">
                            {doctor.open_time?.open_now ? (
                              <span className="text-green-600">Open Now</span>
                            ) : (
                              <span className="text-red-600">Closed</span>
                            )}
                          </p>
                        </div>
                      </>
                    ) : (
                      <div className="max-h-64 overflow-y-auto overflow-x-hidden duration-300" style={{
      scrollbarWidth: "thin",
      scrollbarColor: "rgb(0, 86, 179) #f1f1f1",
    }}>  <style>
      {`
        /* Webkit-based browsers (Chrome, Edge, Safari) */
        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: rgb(0, 86, 179);
          border-radius: 10px;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }
      `}
    </style>
    
                        <p className="font-semibold mb-2 text-sm md:text-base">Recent Reviews</p>
                        {doctor.recent_reviews?.map((review, idx) => (
                          <Review key={idx} review={review} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center w-full">No doctors found nearby.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
