

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

export default function Plandetails({ onUpgradeClick }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [planType, setPlanType] = useState("monthly");
  const [creditsData, setCreditsData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePlanChange = (type) => {
    setPlanType(type);
  };

  const getButtonStyle = (type) => ({
    backgroundColor: "#111827",
    transform: planType === type ? "scale(1.1)" : "scale(1)",
    transition: "transform 0.3s ease",
    fontWeight: planType === type ? "bold" : "normal",
  });

  const handleSubmit = async (price_id, title) => {
    const token = "90a6538b480ee9c033b7deba15a0a1636bb0396c";

    if (token) {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/client_handler/create_checkout_session`,
          headers: { Authorization: `Token ${token}` },
          data: {
            price_id: price_id,
            title: title,
          },
        });
        if (response.data.status === 200) {
          window.location.href = response.data.url;
        } else {
          toast.error("Cannot proceed with payment");
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    } else {
      window.location.href = "/auth/login";
    }
  };

  useEffect(() => {
    const fetchCreditsData = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        setLoading(true);

        try {
          const response = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL}/client_handler/get_credits`,
            headers: { Authorization: `Bearer ${token}` },
          });
console.log(response,"plan details")
          if (response.data.status === 200) {
            setCreditsData(response.data.result);
          } else {
            toast.error("Unable to fetch credits data");
          }
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        } finally {
          setLoading(false);
        }
      } else {
        window.location.href = "/auth/login";
      }
    };

    fetchCreditsData();
  }, []);

  const cardStyles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
      padding: "20px",
    },
    card: {
      minWidth: "450px",
      maxWidth: "800px",
      width: "100%",
      borderRadius: "20px",
      paddingRight: "220px",
      backgroundColor: "white",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    },
  };

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />
        <div
        className={`flex flex-col mt-2 p-5 justify-center items-center ${
          isSidebarOpen ? "md:ml-[350px] md:w-[calc(100%-350px)]" : "w-full"
        }`}
        style={{
          transition: "width 0.3s, margin-left 0.3s",
          backgroundColor: "white",
        }}
      > 
      <div >
        <section
          // className="section pricing-two bg-img"
          style={{ backgroundColor: "white"  }}
        >
         
            <div>
               <section
            style={{ backgroundColor: "white", marginTop: "-30px", overflowY: "hidden" }}
          >
            
            <div className="container">
              <div className="row justify-content-center">
                <div>
                  <div className="section__header">
                    <h3 style={{ width: "100%", textAlign: "center", marginBottom: "10px" }} className="h3">
                    Plan Detail
                    </h3>
                    <h6 style={{ width: "100%", textAlign: "center", color: "#6b7280" }} className="h6">
                    Manage, Upgrade & Explore Your Benefits
                    </h6>
                  </div>
                </div>
              </div>
          
             
              {loading ? (
      
       
          <div className="row justify-content-center gap-3" style={{ marginTop: "50px" }}>
            
            {[...Array(1)].map((_, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundColor: "white",
                        border: "1px solid #e0e0e0",
                        borderRadius: "15px",
                        padding: "20px",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                       
                        minHeight: "450px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        minWidth: "380px",
                        maxWidth: "420px",
                        width: "90%",
                          margin: "90px 75px",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height={40}
                    style={{ marginBottom: "20px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="80%"
                    height={50}
                    style={{ margin: "0 auto 20px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height={1}
                    style={{ marginBottom: "20px" }}
                  />
                  
                  {[...Array(6)].map((_, waveIndex) => (
                    <Skeleton
                    key={waveIndex}
                    variant="text"
                    animation="wave"
                    width="100%"
                    height={20}
                    style={{ marginBottom: "12px" }}
                  />
              
                  ))}
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="60%"
                    height={40}
                    style={{ margin: "20px auto 0" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="60%"
                    height={40}
                    style={{ margin: "20px auto 0" }}
                  />
                </div>
              </div>
            ))}
         
        </div>
          ) : (
              <div className="flex  justify-center items-center">
                 {creditsData && (
                  <div >
                    <div
                      // className="pricing-two__single wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="1200ms"
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #e0e0e0",
                        borderRadius: "15px",
                        padding: "20px",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                       
                        minHeight: "450px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        minWidth: "380px",
                        maxWidth: "420px",
                        width: "90%",
                          margin: "90px 75px",
    willChange: "transform"
                      }}
                     
                    >
                      <div>
                       
                        <h2 className="h2 title text-center" style={{ color: "#111827", margin: "15px 0" }}>
                        {creditsData.subscription_plan}
                        </h2>
                        <hr style={{ borderColor: "#e0e0e0", margin: "15px 0" }} />
                        <ul style={{ listStyle: "none",
                  padding: "0",
                  margin: "0",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px" }}>
                   
                         <li style={{ 
                    display: "flex",
                    alignItems: "flex-start"  ,
                    color:"#111827"
                  }}>
                    <i className="fa-solid fa-check" style={{ 
                      color: "#10b981", 
                      marginRight: "12px",
                      minWidth: "20px",  
                      paddingTop: "2px"  
                    }}></i>Credits Given:{" "}
                      {creditsData.credits_given}</li>
                       <li style={{ 
                    display: "flex",
                    alignItems: "flex-start"  ,
                    color:"#111827"
                  }}>
                    <i className="fa-solid fa-check" style={{ 
                      color: "#10b981", 
                      marginRight: "12px",
                      minWidth: "20px",  
                      paddingTop: "2px"  
                    }}></i> Credits Used:{" "}
                      {creditsData.credits_used}</li>
                   <li style={{ 
                    display: "flex",
                    alignItems: "flex-start"  ,
                    color:"#111827"
                  }}>
                    <i className="fa-solid fa-check" style={{ 
                      color: "#10b981", 
                      marginRight: "12px",
                      minWidth: "20px",  
                      paddingTop: "2px"  
                    }}></i>Credits Remaining:{" "}
                      {creditsData.credits_remaining}</li>
                       <li style={{ 
                    display: "flex",
                    alignItems: "flex-start" ,
                    color:"#111827" 
                  }}>
                    <i className="fa-solid fa-check" style={{ 
                      color: "#10b981", 
                      marginRight: "12px",
                      minWidth: "20px",  
                      paddingTop: "2px"  
                    }}></i>Expiry Date:{" "}
                      {creditsData.expiry_date}</li>
                         
                        </ul>
                        <hr style={{ borderColor: "#e0e0e0", margin: "15px 0" }} />
                        <p style={{ color: "#6b7280", textAlign: "center", margin: "0" }}>Expiration in Days:{" "}
                        {creditsData.expiration_in_days} days</p>
                      </div>
                      <Link to="/upgrade">
                      <button
                        className="btn mt-2"
                        style={{
                          backgroundColor: "#111827",
                          color: "white",
                          width: "60%", 
                          padding: "12px 20px", 
                          borderRadius: "8px",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "14px",
                          fontWeight: "500",
                          margin: "15px auto 0", 
                          display: "block"
                        }}
                      >
                     Upgrade
                      </button>
                     
                    
                    </Link>
                    </div>
                  </div>
                  )}
              </div>
            )}
            </div>
         
          </section>
          {/* <div
              className="flex flex-col justify-center items-center"
              style={{
                maxWidth: "500px",
                width: "100%",
                borderRadius: "20px",
              }}
            >
              {creditsData && (
                <div
                  className="pricing-two__single wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="1200ms"
                  style={{
                    minWidth: "350px",
                    maxWidth: "400px",
                    width: "100%",
                    borderRadius: "20px",
                    padding: "20px",
                  }}
                >
                  <h5
                    className="h5 pri"
                    style={{ textAlign: "center", marginBottom: "20px" }}
                  >
                    Your Subscription Details
                  </h5>
                  <div className="meta">
                    <div className="content">
                      <h2
                        className="h2 title"
                        style={{ color: "white", textAlign: "center" }}
                      >
                        {creditsData.subscription_plan}
                      </h2>
                      <p
                        style={{
                          textAlign: "center",
                          marginBottom: "20px",
                        }}
                      >
                        Current Plan
                      </p>
                    </div>
                  </div>
                  <hr />
                  <ul className="text-left">
                    <li>
                      <i className="fa-solid fa-check"></i> Credits Given:{" "}
                      {creditsData.credits_given}
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i> Credits Used:{" "}
                      {creditsData.credits_used}
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i> Credits Remaining:{" "}
                      {creditsData.credits_remaining}
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i> Expiry Date:{" "}
                      {creditsData.expiry_date}
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i> Expiration in Days:{" "}
                      {creditsData.expiration_in_days} days
                    </li>
                  </ul>
                  <hr />
                  <div className="flex justify-center mt-4">
                    <Link to="/upgrade">
                      <button className="btn btn--septenary">Upgrade</button>
                    </Link>
                  </div>
                </div>
              )}
            </div> */}
            </div>
            
        
        </section>
      </div>
      </div>
    </div>
  );
}
