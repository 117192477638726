import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { FaEdit, FaTrash } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";

const Event = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    // description: "",
    status: false,
    date: "",
    time: "",
    schedule: "does_not_repeat",
  });
  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [clickedIcon, setClickedIcon] = useState(null); // Track clicked icon

  const fetchEvents = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client_handler/api/get-events/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response,"get event")
      setEvents(response.data.events || []);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch events.");
      setLoading(false);
    }
  };

  const fetchEventTypeOptions = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client_handler/api/event-name-choices/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEventTypeOptions(response.data || []);
    } catch (err) {
      toast.error("Failed to fetch event types.");
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchEventTypeOptions();
  }, []);

  const handleToggleStatus = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/client_handler/api/event/${id}/toggle/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Status updated successfully!");
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === id
              ? { ...event, status: response.data.is_active }
              : event
          )
        );
      } else {
        toast.error("Unexpected response. Please try again.");
      }
    } catch (err) {
      toast.error("Failed to update status. Please try again.");
    }
  };

  const handleCreateEvent = () => {
    setShowModal(true);
  };

  const handleEditEvent = (event) => {
    setSelectedEventId(event.id);
    setIsEditMode(true);
    setFormData({
      name: event.name,
      // description: event.description,
      status: event.status,
      date: event.date,
      time: event.time,
      schedule: event.schedule,
    });
    setShowModal(true);
    setClickedIcon(`edit-${event.id}`); // Set clicked icon to edit
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsEditMode(false);
    setFormData({
      name: "",
      // description: "",
      status: false,
      date: "",
      time: "",
      schedule: "",
    });
    setClickedIcon(null); // Reset clicked icon to gray
  };

  const handleDeleteEvent = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/client_handler/api/delete-event/${id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Event deleted successfully!");
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.id !== id)
        );
      } else {
        toast.error("Unexpected response. Please try again.");
      }
    } catch (err) {
      toast.error("Failed to delete event. Please try again.");
    } finally {
      setClickedIcon(null); // Reset clicked icon to gray after deletion
    }
  };
  const formatTimeForBackend = (time24) => {
    const [hours, minutes] = time24.split(":");
    let hour = parseInt(hours, 10);
    const period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12; // Convert 0 to 12 for AM
    return `${hour.toString().padStart(2, "0")}:${minutes} ${period}`;
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const formDataToSend = {
      name: formData.name,
      // description: formData.description,
      status: formData.status,
      date: formData.date,
      time: formatTimeForBackend(formData.time),
      schedule: formData.schedule,
    };
    console.log(formDataToSend, "formDataToSend");
    try {
      if (isEditMode) {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/client_handler/api/update-event/${selectedEventId}/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Event updated successfully!");
          fetchEvents();
          handleCloseModal();
        } else {
          toast.error("Unexpected response. Please try again.");
        }
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client_handler/api/event/create/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Event created successfully!");
          fetchEvents();
          handleCloseModal();
        } else {
          toast.error("Unexpected response. Please try again.");
        }
      }
    } catch (err) {
      toast.error("Failed to process request. Please try again.");
    } finally {
      setClickedIcon(null); // Reset clicked icon to gray after form submission
    }
  };

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />

      <h2
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          marginBottom: "10px",
          textAlign: "left",
        }}
      >
        Create Event
      </h2>

      {loading ? (
        <p>Loading events...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : events.length === 0 ? (
        <p>No events found. Create new event to begin</p>
      ) : (
        <div
          style={{
            maxHeight: "350px",
            overflowY: "auto",
          }}
        >
          <table
  style={{
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    tableLayout: "fixed", // Crucial for consistent column widths
  }}
>
  <thead style={{
    background: "linear-gradient(to right, white 50%, #f0f0f0 50%)", // Half white, half gray background
  }}>
    <tr>
      <th style={thStyle}>Name</th>
      <th style={thStyle}>Date</th>
      <th style={thStyle}>Time</th>
      <th style={thStyle}>Schedule</th>
      <th style={thStyle}>Action</th>
    </tr>
  </thead>
  <tbody>
    {events.map((event) => (
      <tr key={event.id} style={{ height: "60px" }}> {/* Fixed row height */}
        <td style={{ 
          ...tdStyle,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "0", // Helps with text truncation
          verticalAlign: "middle"
        }}>
          {event.name || ""}
        </td>
        <td style={{ ...tdStyle, verticalAlign: "middle" }}>{event.date}</td>
        <td style={{ ...tdStyle, verticalAlign: "middle" }}>
          {new Date(event.updated_at).toLocaleString()}
        </td>
        <td style={{ 
          ...tdStyle,
          verticalAlign: "middle",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}>
          {event.schedule}
        </td>
        <td style={{ 
          ...tdStyle,
          verticalAlign: "middle",
          height: "60px" // Match row height
        }}>
          <div style={{ 
            display: "flex",
            alignItems: "center",
            height: "100%"
          }}>
            <button
              onClick={() => handleToggleStatus(event.id)}
              style={{
                padding: "6px 12px",
                borderRadius: "20px",
                border: "none",
                backgroundColor: event.status ? "#dc3545" : "#0056b3",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                flexShrink: 0 // Prevent button from shrinking
              }}
            >
              {event.status ? "Disable" : "Enable"}
            </button>
            <MdModeEdit
              onClick={() => handleEditEvent(event)}
              style={{
                marginLeft: "10px",
                color: clickedIcon === `edit-${event.id}` ? "#007bff" : "#9ca3af",
                cursor: "pointer",
                fontSize: "18px",
                flexShrink: 0
              }}
            />
            <FaTrash
              onClick={() => handleDeleteEvent(event.id)}
              style={{
                marginLeft: "10px",
                color: clickedIcon === `delete-${event.id}` ? "#007bff" : "#9ca3af",
                cursor: "pointer",
                fontSize: "18px",
                flexShrink: 0
              }}
            />
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>

         
        </div>
      )}
      <button
        onClick={handleCreateEvent}
        type="submit"
        aria-label="create new event"
        className="btn rounded-lg btn--octonary mt-4"
        style={{
          backgroundColor: "#0056b3",
          color: "#fff",
          padding: "12px 16px",
          fontSize: "16px",
          fontWeight: "500",
          // borderRadius: "6px",
          cursor: "pointer",
          // "#dc3545" : "#0056b3"
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s ease, transform 0.2s ease",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = "#0056b3")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.backgroundColor = "#0056b3")
        }
      >
        Create New Event
      </button>

      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "16px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              width: "300px",
            }}
          >
            <h3
              style={{
                marginBottom: "16px",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              {isEditMode ? "Edit Event" : "Create New Event"}
            </h3>
            <form onSubmit={handleFormSubmit}>
              <div style={{ marginBottom: "12px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  Event Type:
                </label>
                <select
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                  }}
                  required
                >
                  <option value="">Select Event Type</option>
                  {eventTypeOptions.map((option) => (
                    <option key={option.key} value={option.key}>
                      {option.key}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  Date:
                </label>
                <input
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                  }}
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={(e) =>
                    setFormData({ ...formData, date: e.target.value })
                  }
                  required
                />
              </div>
              <div style={{ marginBottom: "12px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  Time:
                </label>
                <input
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                  }}
                  type="time"
                  name="time"
                  value={formData.time}
                  onChange={(e) =>
                    setFormData({ ...formData, time: e.target.value })
                  }
                  required
                />
              </div>
              <div style={{ marginBottom: "12px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  Schedule:
                </label>
                <select
                  value={formData.schedule}
                  onChange={(e) =>
                    setFormData({ ...formData, schedule: e.target.value })
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select an option
                  </option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="does_not_repeat">Does not repeat</option>
                  <option value="every_weekday">Every working weekday</option>
                </select>
              </div>

              <div style={{ marginBottom: "12px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "4px",
                    fontSize: "14px",
                  }}
                >
                  Status:
                </label>
                <select
                  value={formData.status}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      status: e.target.value === "true",
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                  }}
                  required
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div style={{ textAlign: "center" }}>
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    padding: "8px 12px",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {isEditMode ? "Update" : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={handleCloseModal}
                  style={{
                    marginLeft: "8px",
                    backgroundColor: "#6c757d",
                    color: "#fff",
                    padding: "8px 12px",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const thStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
  backgroundColor: "white",
  fontWeight:"bold",
};

const tdStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  backgroundColor: "white",
};

export default Event;
